import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import PrimarySkillModel from "../../../../models/admin/skill/response/PrimarySkillModel";
import PrimarySkillRequestModel from "../../../../models/admin/skill/request/PrimarySkillRequestModel";
import { PageModeEnum } from "../../../../models/enums/PageModeEnum";
import DataGrid from "../../../common/table/DataGrid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface PrimarySkillGridProps {
    primarySkillList: PrimarySkillModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    onPrimarySkillAction: (row: PrimarySkillRequestModel, actionType: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
};

const BlueIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const PrimarySkillGridComponent = (props: PrimarySkillGridProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: PrimarySkillModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editPrimarySkill(row); }}>Edit</a>
            </div>
        );
    };

    const editPrimarySkill = (row: PrimarySkillModel) => {
        console.log(row);
        if (loggedInUser !== null) {
            let primarySkillRequestModel: PrimarySkillRequestModel = {
                primarySkillId: row.primarySkillId,
                primarySkillName: row.primarySkillName,
                description: row.description,
                userId: loggedInUser?.userId
            }
            props.onPrimarySkillAction(primarySkillRequestModel, PageModeEnum.Edit);
        }
    };    

    const columnsPrimarySkill = [
        { dataField: "primarySkillName", text: "Primary Skill", sort: true, searchable: true },
        { dataField: "description", text: "Description" },
        { dataField: "actions", text: "", formatter: ActionFormatter, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterOnChange = (key: string, value: string) => {
        console.log(value);
        console.log(value.length);
    };

    return (
        <div className="row">
            <div className="col-md-12">                
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell key="PrimarySkillName" className="table-header-cell">
                                    <TableSortLabel
                                        active={props.sortColumn === "PrimarySkillName"}
                                        direction={props.sortColumn === "PrimarySkillName" ? props.sortDirection : "asc"}
                                        onClick={createSortHandler("PrimarySkillName")}>                                        
                                        Primary Skill
                                    </TableSortLabel>
                                    <input type="text" className="mat-header-filter-input" onChange={(e) => filterOnChange("PrimarySkillName", e.target.value)}></input>
                                </TableCell>
                                <TableCell key="Description">
                                    <TableSortLabel
                                        active={props.sortColumn === "Description"}
                                        direction={props.sortColumn === "Description" ? props.sortDirection : "asc"}
                                        onClick={createSortHandler("Description")}>
                                        Description
                                    </TableSortLabel>
                                    <input type="text" className="mat-header-filter-input" onChange={(e) => filterOnChange("Description", e.target.value)}></input>
                                </TableCell>
                                <TableCell key="Actions">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.primarySkillList.map((skill: PrimarySkillModel, index) => (
                                <TableRow>
                                    <TableCell>{skill.primarySkillName}</TableCell>
                                    <TableCell>{skill.description}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editPrimarySkill(skill); }} title="Edit">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                        {/* <BlueIconButton onClick={handleClick}>
                                            <MoreVertIcon />
                                        </BlueIconButton>
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                            <MenuItem
                                                onClick={() => {
                                                    editPrimarySkill(props.primarySkillList[index]);
                                                }}>
                                                <EditIcon style={{ marginRight: 8 }} /> Edit
                                            </MenuItem>
                                        </Menu> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default PrimarySkillGridComponent;