import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminUserModel from "../../../models/admin/dashboard/response/AdminUserModel";
import AdminUserRequestModel from "../../../models/admin/dashboard/request/AdminUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import DataGrid from "../../common/table/DataGrid";

interface AdminUserGridProps {
    adminUsersList: AdminUserModel[];
    onAdminUserAction: (row: AdminUserRequestModel, actionType: string) => void;
    onAdminUserDeleteAction: (row: AdminUserRequestModel) => void;
    onMapCompanyAction: (adminId: string) => void;
}

const AdminUserGridComponent = (props: AdminUserGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: AdminUserModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editAdminUser(row); }}>Edit</a>
                <a onClick={() => { deleteAdminUser(row); }}>Delete</a>
                <a onClick={() => { mapCompany(row); }}>Map Company</a>
            </div>
        );
    };

    const editAdminUser = (row: AdminUserModel) => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: row.adminId,
                emailId: row.emailId,
                userName: row.emailId,
                mobileNumber: row.mobileNumber,
                name: row.name,
                userId: loggedInUser?.userId
            }
            props.onAdminUserAction(adminUserRequestModel, PageModeEnum.Edit);
        }
    };

    const deleteAdminUser = (row: AdminUserModel) => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: row.adminId,
                emailId: row.emailId,
                userName: row.emailId,
                mobileNumber: row.mobileNumber,
                name: row.name,
                userId: loggedInUser?.userId
            }
            props.onAdminUserDeleteAction(adminUserRequestModel);
        }
    };

    const mapCompany = (row: AdminUserModel) => {
        props.onMapCompanyAction(row.adminId);
    };

    const addAdminUser = () => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: GuidEnum.Empty,
                emailId: "",
                userName: "",
                mobileNumber: null,
                name: "",
                userId: loggedInUser?.userId
            }
            props.onAdminUserAction(adminUserRequestModel, PageModeEnum.Create);
        }
    };

    const columnsAdminUser = [
        { dataField: "name", text: "Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addAdminUser}> + Add User</button>
                <DataGrid
                    uniqueField="adminId"
                    data={props.adminUsersList}
                    columns={columnsAdminUser}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default AdminUserGridComponent;