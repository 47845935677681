import React from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import SubUserModel from "../../../models/company/subUser/response/SubUserModel";
import SubUserRequestModel from "../../../models/company/subUser/request/SubUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface SubUserGridProps {
    subUserList: SubUserModel[];
    onSubUserAction: (row: SubUserRequestModel, actionType: string) => void;
}

const SubUserGridComponent = (props: SubUserGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);    

    const editSubUser = (row: SubUserModel) => {
        if (loggedInUser !== null) {
            let subUserRequestModel: SubUserRequestModel = {
                subUserId: row.subUserId,
                name: row.name,
                userName: row.emailId,
                emailId: row.emailId,
                mobileNumber: row.mobileNumber,
                departmentId: "",
                cityId: 0,
                roleId: 0,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onSubUserAction(subUserRequestModel, PageModeEnum.Edit);
        }
    }

    const addSubUser = () => {
        if (loggedInUser !== null) {
            let subUserRequestModel: SubUserRequestModel = {
                subUserId: GuidEnum.Empty,
                name: "",
                userName: "",
                emailId: "",
                mobileNumber: 0,
                departmentId: "",
                cityId: 0,
                roleId: 0,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onSubUserAction(subUserRequestModel, PageModeEnum.Create);
        }
    }

    const columnsSubUsers = [
        { dataField: "name", text: "Name" },
        { dataField: "emailId", text: "Email" },
        { dataField: "roleName", text: "Role" },
        { dataField: "departmentName", text: "Department" },
        { dataField: "cityName", text: "Location" }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addSubUser}> + Add SubUser</button>
                <DataGrid
                    uniqueField="subUserId"
                    data={props.subUserList}
                    columns={columnsSubUsers}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default SubUserGridComponent;