import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import AddProfilesGridModel from "../../../models/company/addProfiles/response/AddProfilesGridModel";
import AddProfileSearchInputModel from "../../../models/company/addProfiles/request/AddProfileSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import addProfilesService from "../../../services/company/add-profiles-service";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import IdNameModel from "../../../models/common/IdNameModel";
import AddProfilesGridComponent from "./AddProfilesGridComponent";
import AddProfilesRequestModel from "../../../models/company/addProfiles/request/AddProfilesRequestModel";
import AddCandidatePopUp from "./AddCandidateComponent";
import { FileUploadTypeEnum } from "../../../models/enums/FileUploadTypeEnum";
import fileUploadService from "../../../services/file-upload-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import AddProfilesStatusUpdateModel from "../../../models/company/addProfiles/request/AddProfilesStatusUpdateModel";
import StatusUpdatePopUp from "./StatusUpdateComponent";
import masterDataService from "../../../services/master-data-service";
import ScheduleInterviewCompanyRequestModel from "../../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../utils/loader-utils";

interface AddProfilesProps {
    vendorList: IdNameModel[];
    statusList: IdNameModel[];
    requirementId: string;
    profileId: string;
    userId: string;
};

const AddProfilesComponent = (props: AddProfilesProps) => {
    //let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [addProfilesPagedList, setAddProfilesPagedList] = useState<PagedListModel<AddProfilesGridModel>>();
    const [addProfilesList, setAddProfilesList] = useState<AddProfilesGridModel[]>([]);
    const [refreshAddProfiles, setRefreshAddProfiles] = useState<number>(2);
    const [timeSlots, setTimeSlots] = useState<IdNameModel[]>([]);

    useEffect(() => {
        getTimeSlots();
        getAddProfiles();
    }, []);

    const getAddProfiles = () => {
        var inputData: AddProfileSearchInputModel = {
            requirementId: props.requirementId,
            statusId: 0,
            candidateName: "",
            page: 1,
            pageSize: 100,
            sort: "CandidateName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.getAddProfilesGrid(inputData).then((res) => {
            modalUtils.closeModal();
            setAddProfilesList([]);
            setAddProfilesPagedList(res);
            setAddProfilesList(res.items);
            setRefreshAddProfiles(refreshAddProfiles * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const getTimeSlots = () => {
        masterDataService.loadTimeSlots().then((res) => {
            if (res.isSuccess) {
                setTimeSlots(res.items);
            } else {
                alertUtils.showError("Unable to load Primary skills");
            }
        }).catch((error) => {
            alertUtils.showError("Erorr fetching Primary skills");
        });
    };

    /* Start - Add Candidate Flow */
    const openAddProfileSaveModel = (addProfileRequestModel: AddProfilesRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Candidate",
            content: (
                <AddCandidatePopUp
                    objAddProfile={addProfileRequestModel}
                    vendorList={props.vendorList}
                    onCloseAddProfile={onCloseAddProfileHandler}
                    onAddProfileSave={onAddProfileSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseAddProfileHandler = () => {
        modalUtils.closeModal();
    };

    const onAddProfileSaveHandler = (objAddProfile: AddProfilesRequestModel, resume: any) => {
        setRefreshAddProfiles(2);
        objAddProfile.requirementId = props.requirementId;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.addRequirementProfile(objAddProfile).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            const data = new FormData();
            data.append("file", resume.target.files[0]);
            data.append("fileName", resume.target.files[0].name);
            data.append("userId", res.data.profileId);
            data.append("fileUploadType", FileUploadTypeEnum.CandidateResume.toString());
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            fileUploadService.uploadFile(data).then((res) => {
                modalUtils.closeModal();
                alertUtils.showSuccess(res.informationMessages.toString());
                getAddProfiles();
            }).catch((error) => {
                setRefreshAddProfiles(refreshAddProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onProfileAddActionHandler = (actionType: string) => {
        let addProfileRequestModel: AddProfilesRequestModel = {
            requirementId: props.requirementId,
            userId: GuidEnum.Empty,
            name: "",
            userName: "",
            emailId: "",
            mobileNumber: null,
            vendorId: ""
        };
        openAddProfileSaveModel(addProfileRequestModel, actionType);
    };

    /* End - Add Candidate Flow */

    /* Start - Status Update Flow */
    const openStatusUpdateModel = (statusUpdateRequestModel: AddProfilesStatusUpdateModel) => {
        const config: GeneralPoupConfig = {
            title: "Update Status",
            content: (
                <StatusUpdatePopUp
                    objStatusUpdate={statusUpdateRequestModel}
                    statusList={props.statusList}
                    onCloseStatusUpdate={onCloseStatusUpdateHandler}
                    onStatusUpdate={onStatusUpdateHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseStatusUpdateHandler = () => {
        modalUtils.closeModal();
    };

    const onStatusUpdateHandler = (objStatusUpdate: AddProfilesStatusUpdateModel) => {
        setRefreshAddProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.updateProfileStatus(objStatusUpdate).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getAddProfiles();
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onStatusUpdateActionHandler = (objProfile: AddProfilesGridModel) => {
        let statusUpdateRequestModel: AddProfilesStatusUpdateModel = {
            profileId: objProfile.profileId,
            userId: GuidEnum.Empty,
            statusId: objProfile.statusId,
            comments: objProfile.comments
        };
        openStatusUpdateModel(statusUpdateRequestModel);
    };
    /* End - Status Update Flow */

    /* Start - Schedule Interview Flow */
    const onScheduleInterviewActionHandler = (objScheduleInterview: ScheduleInterviewCompanyRequestModel) => {
        setRefreshAddProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.scheduleInterview(objScheduleInterview).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getAddProfiles();
            modalUtils.closeModal();
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onRatingDetailsHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.interviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Rating Details",
                    content: (
                        <RatingDetailsPopUp
                            objRatingDetails={res.data}
                        />
                    ),
                    size: ModalSize.XL,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Schedules");
        });
    };
    /* End - Schedule Interview Flow */

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshAddProfiles > 2 && timeSlots.length > 0 &&
                    <AddProfilesGridComponent
                        key={refreshAddProfiles}
                        addProfilesList={addProfilesList}
                        requirementId={props.requirementId}
                        timeSlotsList={timeSlots}
                        onProfileAdd={onProfileAddActionHandler}
                        onStatusUpdate={onStatusUpdateActionHandler}
                        onScheduleInterview={onScheduleInterviewActionHandler}
                        onRatingDetails={onRatingDetailsHandler} />
                }
            </div>
        </div>
    );
};
export default AddProfilesComponent;