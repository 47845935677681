import React from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import DepartmentModel from "../../../models/company/department/response/DepartmentModel";
import DepartmentRequestModel from "../../../models/company/department/request/DepartmentRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface DepartmentGridProps {
    departmentList: DepartmentModel[];
    onDepartmentAction: (row: DepartmentRequestModel, actionType: string) => void;
}

const DepartmentGridComponent = (props: DepartmentGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: DepartmentModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editDepartment(row); }}>Edit</a>
            </div>
        );
    };

    const editDepartment = (row: DepartmentModel) => {
        if (loggedInUser !== null) {
            let departmentRequestModel: DepartmentRequestModel = {
                departmentId: row.departmentId,
                departmentName: row.departmentName,
                description: row.description,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onDepartmentAction(departmentRequestModel, PageModeEnum.Edit);
        }
    }

    const addDepartment = () => {
        if (loggedInUser !== null) {
            let departmentRequestModel: DepartmentRequestModel = {
                departmentId: GuidEnum.Empty,
                departmentName: "",
                description: "",
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onDepartmentAction(departmentRequestModel, PageModeEnum.Create);
        }
    }

    const columnsDepartments = [
        { dataField: "departmentName", text: "Department Name" },
        { dataField: "description", text: "Description" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addDepartment}> + Add Department</button>
                <DataGrid
                    uniqueField="departmentId"
                    data={props.departmentList}
                    columns={columnsDepartments}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default DepartmentGridComponent;