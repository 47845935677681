import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import ReleaseCandidatesModel from "../../../../models/admin/company/response/ReleaseCandidatesModel";
import alertUtils from "../../../../utils/toaster-utils";
import ReleaseCandidatesSeachInputModel from "../../../../models/admin/company/request/ReleaseCandidatesSeachInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import PagedListModel from "../../../../models/common/PagedListModel";
import ReleaseCandidateGridComponent from "./ReleaseCandidateGridComponent";
import ReleaseCandidatesRequestModel from "../../../../models/admin/company/request/ReleaseCandidatesRequestModel";

interface ReleaseCandidatesProps {
    companiesList: IdNameModel[];
    primarySkillList: IdNameModel[];
};

const ReleaseCandidatesComponent = (props: ReleaseCandidatesProps) => {
    const [candidatesPagedList, setCandidatesPagedList] = useState<PagedListModel<ReleaseCandidatesModel>>();
    const [candidatesList, setCandidatesList] = useState<ReleaseCandidatesModel[]>([]);
    const [refreshCandidates, setRefreshCandidates] = useState<number>(2);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [primarySkillSearch, setPrimarySkillSearch] = useState<number>(0);

    useEffect(() => {
        clearSearch();
    }, []);

    const clearSearch = () => {
        setCandidatesList([]);
        setPrimarySkillSearch(0);
        setCompanySearch("");
        setRefreshCandidates(2);
    };

    const getReleaseCandidates = () => {
        if (companySearch === "" || primarySkillSearch === 0) {
            alertUtils.showWarning("Please select Company, Primary Skill");
            return;
        }
        setRefreshCandidates(2);
        var inputData: ReleaseCandidatesSeachInputModel = {
            companyId: companySearch,
            primarySkillId: primarySkillSearch,
            candidateName: "",
            emailId: "",
            jobCode: "",
            page: 1,
            pageSize: 100,
            sort: "CandidateName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getCandidatesToBeReleased(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidatesList([]);
            setCandidatesPagedList(res);
            setCandidatesList(res.items);
            setRefreshCandidates(refreshCandidates * 5);
        }).catch((error) => {
            setRefreshCandidates(refreshCandidates * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onReleaseCandidateActionHandler = (objReleaseCandidate: ReleaseCandidatesRequestModel) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.releaseCandidate(objReleaseCandidate).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getReleaseCandidates();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Company</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setCompanySearch(e.target.value)}>
                            <option value="">Select Company</option>
                            {props.companiesList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="">Select Company</option>
                            {props.primarySkillList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getReleaseCandidates()}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidatesList.length > 0 && refreshCandidates > 2 &&
                    <ReleaseCandidateGridComponent
                        key={refreshCandidates}
                        candidateList={candidatesList}
                        onReleaseCandidateAction={onReleaseCandidateActionHandler} />
                }
            </div>
        </div>
    );
};
export default ReleaseCandidatesComponent;