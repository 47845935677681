import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import CandidateAccessToViewProfileGridModel from "../../../models/candidate/response/CandidateAccessToViewProfileGridModel";
import CandidateAccessToViewProfileRequestModel from "../../../models/candidate/request/CandidateAccessToViewProfileRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import DataGrid from "../../common/table/DataGrid";

interface CandidateAccessGridProps {
    accessList: CandidateAccessToViewProfileGridModel[];
    onProvideAccess: (row: CandidateAccessToViewProfileRequestModel) => void;
}

const CandidateAccessGridComponent = (props: CandidateAccessGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: CandidateAccessToViewProfileGridModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { provideAccess(row); }}>Provide Access</a>
            </div>
        );
    };

    const provideAccess = (row: CandidateAccessToViewProfileGridModel) => {
        if (loggedInUser?.profileId) {
            var objAccess: CandidateAccessToViewProfileRequestModel = {
                accessId: row.accessId,
                candidateId: loggedInUser.profileId,
                hasAccess: true,
                userId: GuidEnum.Empty
            }
            props.onProvideAccess(objAccess);
        }
    };

    const columnsAccess = [
        { dataField: "companyName", text: "Company" },
        { dataField: "designationName", text: "Designation" },
        { dataField: "jobCode", text: "Job Code" },
        { dataField: "hasAccess", text: "Has Access" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="accessId"
                    data={props.accessList}
                    columns={columnsAccess}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default CandidateAccessGridComponent;