import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import vendorService from "../../../services/company/vendor-service";
import VendorSearchInputModel from "../../../models/company/vendor/request/VendorSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import VendorPopUp from "./VendorCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import VendorRequestModel from "../../../models/company/vendor/request/VendorRequestModel";
import VendorGridComponent from "./VendorGridComponent";
import loaderUtils from "../../../utils/loader-utils";

const VendorComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorPagedList, setVendorPagedList] = useState<PagedListModel<VendorRequestModel>>();
    const [vendorList, setVendorList] = useState<VendorRequestModel[]>([]);
    const [refreshVendor, setRefreshVendor] = useState<number>(2);

    useEffect(() => {
        getVendors();
    }, []);

    const getVendors = () => {
        if (loggedInUser?.profileId) {
            var inputData: VendorSearchInputModel = {
                companyId: loggedInUser?.profileId,
                vendorName: "",
                page: 1,
                pageSize: 100,
                sort: "VendorName",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            vendorService.getVendors(inputData).then((res) => {
                modalUtils.closeModal();
                setVendorList([]);
                setVendorPagedList(res);
                setVendorList(res.items);
                setRefreshVendor(refreshVendor * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openVendorSaveModel = (vendorRequestModel: VendorRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Vendor",
            content: (
                <VendorPopUp
                    objVendor={vendorRequestModel}
                    actionType={actionType}
                    onCloseVendor={onCloseVendorHandler}
                    onVendorSave={onVendorSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    const onCloseVendorHandler = () => {
        modalUtils.closeModal();
    }

    const onVendorSaveHandler = (objVendor: VendorRequestModel) => {
        setRefreshVendor(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        vendorService.saveVendor(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());                
                getVendors();
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    }

    const onVendorActionHandler = (obj: VendorRequestModel, actionType: string) => {
        openVendorSaveModel(obj, actionType);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshVendor > 2 &&
                    <VendorGridComponent
                        key={refreshVendor}
                        vendorList={vendorList}
                        onVendorAction={onVendorActionHandler} />
                }
            </div>
        </div>
    );
};
export default VendorComponent;