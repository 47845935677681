import React from "react";
import {  useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingInterviewProcessRequestModel from "../../../models/company/jobPosting/request/JobPostingInterviewProcessRequestModel";
import InterviewProcessModel from "../../../models/company/jobPosting/response/InterviewProcessModel";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";

interface JobPostingInterviewProcessGridProps {
    jobPostingInterviewProcessList: InterviewProcessModel[];
    onJobPostingInterviewProcessAction: (row: JobPostingInterviewProcessRequestModel, actionType: string) => void;
}

const JobPostingInterviewProcessGridComponent = (props: JobPostingInterviewProcessGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();

    const ActionFormatter = (
        cell: any,
        row: InterviewProcessModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">                
                <a style={{display: 'block'}} onClick={() => { editJobPostingInterviewProcess(row); }}>Edit</a>
            </div>
        );
    };

    const editJobPostingInterviewProcess = (row: InterviewProcessModel) => {
        if (loggedInUser !== null) {
            let jobPostingInterviewProcessRequestModel: JobPostingInterviewProcessRequestModel = {
                requirementInterviewProcessId: row.requirementInterviewProcessId,
                requirementId: row.requirementId,
                interviewRoundId: row.interviewRoundId,
                recruiterId: loggedInUser.profileId,
                userId: loggedInUser.userId,
                jobPostingSkillRequestModel: []
            }
            props.onJobPostingInterviewProcessAction(jobPostingInterviewProcessRequestModel, PageModeEnum.Edit);
        }
    };

    const addJobPostingInterviewProcess = () => {
        if (loggedInUser !== null) {
            let baseSkillDetails: JobPostingSkillRequestModel[] = [];
            let minSkills: number = 3;
            for (let i = 1; i <= minSkills; i++) {
                let skill: JobPostingSkillRequestModel = {
                    requirementSkillId: GuidEnum.Empty,
                    requirementId: GuidEnum.Empty,
                    secondarySkillId: null,
                    secondarySkillNumber: i,
                    isCommentsRequired: true,
                    requirementInterviewProcessId: GuidEnum.Empty,
                    recruiterId: GuidEnum.Empty,
                    userId: GuidEnum.Empty
                };
                baseSkillDetails.push(skill);
            }
            let jobPostingInterviewProcessRequestModel: JobPostingInterviewProcessRequestModel = {
                requirementInterviewProcessId: GuidEnum.Empty,
                requirementId: GuidEnum.Empty,
                interviewRoundId: null,
                recruiterId: loggedInUser.profileId,
                userId: loggedInUser.userId,
                jobPostingSkillRequestModel: baseSkillDetails
            }
            props.onJobPostingInterviewProcessAction(jobPostingInterviewProcessRequestModel, PageModeEnum.Create);
        }
    };

    const columnsJobPostingInterviewProcess = [
        { dataField: "interviewRoundName", text: "Interview Step" },
        { dataField: "interviewRoundNumber", text: "Round" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addJobPostingInterviewProcess}> + Add Interview Process</button>
                <DataGrid
                    uniqueField="requirementInterviewProcessId"
                    data={props.jobPostingInterviewProcessList}
                    columns={columnsJobPostingInterviewProcess}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default JobPostingInterviewProcessGridComponent;