import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import RegisteredCandidatesModel from "../../../../models/admin/candidate/response/RegisteredCandidatesModel";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import alertUtils from "../../../../utils/toaster-utils";

interface RegisteredCandidatesGridProps {
    candidateList: RegisteredCandidatesModel[];
    onDownloadResumeAction: (candidateId: string, candidateName: string) => void;
    onScheduleAction: (candidateId: string) => void;
    onVerifyAction: (candidateId: string) => void;
}

const RegisteredCandidatesGridComponent = (props: RegisteredCandidatesGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: RegisteredCandidatesModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { downloadResume(row.candidateId, row.candidateName); }}>Resume</a>
                {!row.isVerified &&
                    <a onClick={() => { verifyUser(row.candidateId); }}>Authorize</a>
                }
                {!row.isInterviewScheduled &&
                    <a onClick={() => { scheduleInterview(row.candidateId, row.isVerified); }}>Schedule</a>
                }
            </div>
        );
    };

    const CopyMobileFormatter = (
        cell: any,
        row: RegisteredCandidatesModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <span>{row.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(row.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
            </div>
        );
    };

    const CopyUniqueFormatter = (
        cell: any,
        row: RegisteredCandidatesModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <span>{row.uniqueId}</span><a title="copy" onClick={() => { copyClipboard(row.uniqueId); }}><i className="fa fa-clipboard"></i></a>
            </div>
        );
    };

    const downloadResume = (candidateId: string, candidateName: string) => {
        props.onDownloadResumeAction(candidateId, candidateName);
    };

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const scheduleInterview = (candidateId: string, isVerified: boolean) => {
        if (isVerified) {
            props.onScheduleAction(candidateId);
        } else {
            alertUtils.showWarning("Candidate Is not verified.");
        }
    };

    const verifyUser = (candidateId: string) => {
        props.onScheduleAction(candidateId);
    };

    const columnsCandidates = [
        { dataField: "uniqueId", text: "UniqueId", formatter: CopyUniqueFormatter },
        { dataField: "candidateName", text: "Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No", formatter: CopyMobileFormatter },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "experienceName", text: "Experience" },
        { dataField: "registeredDate", text: "RegisteredDate" },
        { dataField: "isVerified", text: "Verified" },
        { dataField: "interviewDate", text: "Interview Date" },
        { dataField: "totalRating", text: "Rating" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="candidateId"
                    data={props.candidateList}
                    columns={columnsCandidates}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default RegisteredCandidatesGridComponent;