import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import AdminAddProfilesComponent from "../../../components/admin/company/admin-add-profiles/AdminAddProfilesComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import adminCompanyService from "../../../services/admin/admin-company-service";
import addProfilesService from "../../../services/company/add-profiles-service";
import { ScreenEnum } from "../../../models/enums/StatusEnum";

const AdminAddProfiles = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [companyList, setCompaniesList] = useState<IdNameModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);
    const [vendorList, setVendorList] = useState<IdNameModel[]>([]);
    const [statusList, setStatusList] = useState<IdNameModel[]>([]);
    const [refreshStatus, setRefreshStatus] = useState<number>(2);
    const [refreshVendors, setRefreshVendors] = useState<number>(2);

    useEffect(() => {
        fillCompanies();
    }, []);

    const fillCompanies = () => {
        console.log(loggedInUser)
        setRefreshCompany(2);
        if (loggedInUser && parseInt(loggedInUser.roleId) === parseInt(RoleEnum.Administrator.toString())) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            masterDataService.loadCompanies().then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            adminCompanyService.getCompaniesForIAIRecruiter(loggedInUser ? loggedInUser.profileId : "").then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const getVendorList = (companyId: string, requirementId: string) => {
        addProfilesService.fillVendorsForAddProfiles(companyId, requirementId).then((res) => {
            setVendorList(res.items);
            setRefreshVendors(refreshVendors * 5);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getStatusList = () => {
        masterDataService.loadStatusByScreen(ScreenEnum.AddProfileStatus).then((res) => {
            setStatusList(res.items);
            setRefreshStatus(refreshStatus * 5);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Add Profiles </h4>
            </div>
            {refreshCompany > 2 &&
                <AdminAddProfilesComponent
                    companiesList={companyList}
                    vendorList={vendorList}
                    statusList={statusList} />
            }
        </Layout>
    );
};
export default AdminAddProfiles;