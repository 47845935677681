import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import IdNameModel from "../../../models/common/IdNameModel";
import CandidateRelatedJobRequirementsGridModel from "../../../models/candidate/response/CandidateRelatedJobRequirementsGridModel";
import CandidateRelatedJobRequirementRequestModel from "../../../models/candidate/request/CandidateRelatedJobRequirementRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import companyClasses from "../../../landing-pages/company/company.module.css";

interface CandidateJobGridProps {
    requirementList: CandidateRelatedJobRequirementsGridModel[];
    onApply: (row: CandidateRelatedJobRequirementRequestModel) => void;
}

const CandidateNewJobsGridComponent = (props: CandidateJobGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);

    const applyForJob = (row: CandidateRelatedJobRequirementsGridModel) => {
        if (loggedInUser?.profileId) {
            var objRequirement: CandidateRelatedJobRequirementRequestModel = {
                requirementId: row.requirementId,
                candidateId: loggedInUser.profileId,
                interviewId: row.interviewId,
                userId: GuidEnum.Empty
            }
            props.onApply(objRequirement);
        }
    };

    return (
        <div className="row">
            {props.requirementList.map((requirement: CandidateRelatedJobRequirementsGridModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{requirement.jobCode}</h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Exp </b>: {requirement.experience}</p>
                                    <p><b>Designation</b>: {requirement.designationName}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Primary Skill</b>: {requirement.primarySkillName}</p>
                                    <p><b>Skills</b>: {requirement.secondarySkills}</p>
                                </div>
                                <div className="col-md-4">
                                    <p><b>Location</b>: {requirement.cityName}</p>
                                    <p><b>Job Type</b>: {requirement.jobTypeName}</p>
                                    <button className="btn btn-warning" type="button" onClick={() => applyForJob(requirement)}> Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
export default CandidateNewJobsGridComponent;