import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import RegisteredInterviewerModel from "../../../../models/admin/interviewer/response/RegisteredInterviewerModel";

interface RegisteredInterviewersGridProps {
    interviewersList: RegisteredInterviewerModel[];
    onApproveAction: (interviewerId: string) => void;
}

const RegisteredInterviewersGridComponent = (props: RegisteredInterviewersGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: RegisteredInterviewerModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                {!row.isApproved &&
                    <a onClick={() => { approveInterviewer(row.interviewerId); }}>Approve</a>
                }
            </div>
        );
    };

    const approveInterviewer = (interviewerId: string) => {
        props.onApproveAction(interviewerId);
    };

    const columnsInterviewers = [
        { dataField: "name", text: "Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No" },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "location", text: "Location" },
        { dataField: "registeredDate", text: "Registered Dt" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="interviewerId"
                    data={props.interviewersList}
                    columns={columnsInterviewers}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default RegisteredInterviewersGridComponent;