import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import RelatedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import ShortlistedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/ShortlistedProfilesGridModel";
import companyClasses from "../../../landing-pages/company/company.module.css";
import ShortlistedProfileStatusUpdateRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfileStatusUpdateRequestModel";

interface ShortlistedProfilesGridProps {
    shortlistedProfilesList: ShortlistedProfilesGridModel[];
    requirementId: string;
    onStatusUpdate: (row: ShortlistedProfileStatusUpdateRequestModel) => void;
    onRatingDetails: (row: RelatedProfilesRequestModel, hasAccess: boolean) => void;
};

const ShortlistedProfilesGridComponent = (props: ShortlistedProfilesGridProps) => {

    const updateStatus = (objProfile: ShortlistedProfilesGridModel) => {
        let updateModel: ShortlistedProfileStatusUpdateRequestModel = {
            companySelectedProfileId: objProfile.companySelectedProfileId,
            statusId: objProfile.statusId,
            followUpDate: new Date(objProfile.followUpDate),
            comments: objProfile.comments,
            userId: GuidEnum.Empty
        }
        props.onStatusUpdate(updateModel);
    };

    const viewRating = (objProfile: ShortlistedProfilesGridModel) => {
        let updateModel: RelatedProfilesRequestModel = {
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onRatingDetails(updateModel, objProfile.restrictEmployerToViewProfile);
    };

    return (
        <div className="row">
            {props.shortlistedProfilesList.map((profile: ShortlistedProfilesGridModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{profile.candidateName} </h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-3">
                                    <p><b>Exp </b>: {profile.experience}</p>
                                    <p><b>Status</b>: {profile.statusName}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Interview Date</b>: {profile.interviewDate}</p>
                                    <p><b>Rating</b>: {profile.totalRating}</p>
                                </div>
                                <div className="col-md-3">
                                    <div className="input-group-btn input-group-prepend last">
                                        <ul className="list-inline ">
                                            <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => viewRating(profile)}> Rating</a></h6></li>
                                            <button className="btn btn-warning" type="button" onClick={() => updateStatus(profile)}> Update Status</button>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
export default ShortlistedProfilesGridComponent;