import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";

interface ByPassPaymentGatewayGridProps {
    candidateList: CandidateAdminModel[];
    onByPassPaymentGatewayAction: (candidateId: string) => void;
}
const ByPassPaymentGatewayGridComponent = (props: ByPassPaymentGatewayGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: CandidateAdminModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <button className="btn btn-success" onClick={() => { byPassAction(row.candidateId); }}>By Pass Payment Gateway</button>
            </div>
        );
    };

    const byPassAction = (candidateId: string) => {
        props.onByPassPaymentGatewayAction(candidateId);
    };

    const columnsAdminUser = [
        { dataField: "uniqueId", text: "UniqueId" },
        { dataField: "candidateName", text: "Name" },
        { dataField: "emailId", text: "Email Id" },        
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="candidateId"
                    data={props.candidateList}
                    columns={columnsAdminUser}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default ByPassPaymentGatewayGridComponent;