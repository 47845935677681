import { useState, MouseEvent } from "react";
import DataGrid from "../../../common/table/DataGrid";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import EditRatingModel from "../../../../models/admin/interviews/response/EditRatingModel";

interface EditRatingGridProps {
    interviewList: EditRatingModel[];
    onRatingEditAction: (interviewId: string) => void;
}

const EditRatingGridComponent = (props: EditRatingGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: EditRatingModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">                
                <a onClick={() => { updateRating(row); }}>Update</a>
            </div>
        );
    };

    const updateRating = (row: EditRatingModel) => {
        props.onRatingEditAction(row.interviewId);
    };

    const columnsInterviews = [
        { dataField: "interviewUniqueId", text: "Interview Id" },
        { dataField: "interviewDate", text: "Interview Dt" },
        { dataField: "candidateName", text: "Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No" },
        { dataField: "interviewerName", text: "Interviewer" },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "totalRating", text: "Rating" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="interviewId"
                    data={props.interviewList}
                    columns={columnsInterviews}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default EditRatingGridComponent;