import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import SecondarySkillRequestModel from "../../../../models/admin/skill/request/SecondarySkillRequestModel";
import SecondarySkillModel from "../../../../models/admin/skill/response/SecondarySkillModel";
import { PageModeEnum } from "../../../../models/enums/PageModeEnum";
import DataGrid from "../../../common/table/DataGrid";

interface SecondarySkillGridProps {
    secondarySkillList: SecondarySkillModel[];
    onSecondarySkillAction: (row: SecondarySkillRequestModel, actionType: string) => void;
}

const SecondarySkillGridComponent = (props: SecondarySkillGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: SecondarySkillModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editSecondarySkill(row); }}>Edit</a>
            </div>
        );
    };

    const editSecondarySkill = (row: SecondarySkillModel) => {
        if (loggedInUser !== null) {
            let secondarySkillRequestModel: SecondarySkillRequestModel = {
                secondarySkillId: row.secondarySkillId,
                primarySkillId: row.primarySkillId,
                secondarySkillName: row.secondarySkillName,
                description: row.description,
                userId: loggedInUser?.userId
            }
            props.onSecondarySkillAction(secondarySkillRequestModel, PageModeEnum.Edit);
        }
    };

    const addSecondarySkill = () => {
        if (loggedInUser !== null) {
            let secondarySkillRequestModel: SecondarySkillRequestModel = {
                secondarySkillId: 0,
                primarySkillId: 0,
                secondarySkillName: "",
                description: "",
                userId: loggedInUser?.userId
            }
            props.onSecondarySkillAction(secondarySkillRequestModel, PageModeEnum.Create);
        }
    };

    const columnsSecondarySkill = [
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "secondarySkillName", text: "Secondary Skill" },
        { dataField: "description", text: "Description" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addSecondarySkill}> + Add Secondary Skill</button>
                <DataGrid
                    uniqueField="primarySkillId"
                    data={props.secondarySkillList}
                    columns={columnsSecondarySkill}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default SecondarySkillGridComponent;