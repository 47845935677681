import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import RegisteredInterviewerModel from "../../../../models/admin/interviewer/response/RegisteredInterviewerModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import RegisteredInterviewerSearchInputModel from "../../../../models/admin/interviewer/request/RegisteredInterviewerSearchInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminInterviewerService from "../../../../services/admin/admin-interviewer-service";
import alertUtils from "../../../../utils/toaster-utils";
import RegisteredInterviewersGridComponent from "./RegisteredInterviewersGridComponent";

interface RegisteredInterviewersProps {
    primarySkillsList: IdNameModel[];
};

const RegisteredInterviewersComponent = (props: RegisteredInterviewersProps) => {
    const [interviewerPagedList, setInterviewerPagedList] = useState<PagedListModel<RegisteredInterviewerModel>>();
    const [interviewerList, setInterviewerList] = useState<RegisteredInterviewerModel[]>([]);
    const [refreshInterviewer, setRefreshInterviewer] = useState<number>(2);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);

    const getRegisteredInterviewers = () => {
        if(primarySKillSearch == 0){
            alertUtils.showWarning("Please select Primary Skill.");
            return;
        }
        setRefreshInterviewer(2);
        var inputData: RegisteredInterviewerSearchInputModel = {
            primarySkillId: primarySKillSearch,
            name: "",
            emailId: "",
            page: 1,
            pageSize: 100,
            sort: "name",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewerService.getRegisteredInterviewers(inputData).then((res) => {
            modalUtils.closeModal();
            setInterviewerList([]);
            setInterviewerPagedList(res);
            setInterviewerList(res.items);
            setRefreshInterviewer(refreshInterviewer * 5);            
        }).catch((error) => {
            setRefreshInterviewer(refreshInterviewer * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onApproveActionHandler =(interviewerId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminInterviewerService.approveInterviewer(interviewerId).then((res) => {
            modalUtils.closeModal();
            if(res.data){
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }            
            getRegisteredInterviewers();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };


    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">                    
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getRegisteredInterviewers()}>
                            <i className="icon-search">Search</i>
                        </button>
                    </div>
                </div>
                {interviewerList.length > 0 && refreshInterviewer > 2 &&
                    <RegisteredInterviewersGridComponent
                        key={refreshInterviewer}
                        interviewersList={interviewerList}
                        onApproveAction={onApproveActionHandler} />
                }
            </div>
        </div>
    );
};
export default RegisteredInterviewersComponent;