import React, { useState, useEffect } from "react";
import InterviewerSchedulesModel from "../../../../models/interviewer/response/InterviewerSchedulesModel";
import dateUtils from "../../../../utils/date-utils";
import DataGrid from "../../../common/table/DataGrid";

interface InterviewerProfileProps {
    interviewerSchedule: InterviewerSchedulesModel;
}

const InterviewerScheduleGridComponent = (props: InterviewerProfileProps) => {
    const dateFormatter = (
        cell: any,
        row: InterviewerSchedulesModel,
        rowIndex: number,
        formatExtraData: any
    ) => {
        if (row.date) {
            return dateUtils.getFormattedDateFromDate(row.date) + "-" + dateUtils.getDayFromDate(row.date);
        } else {
            return "";
        }
    };

    const columnsInterviewerSchedule = [
        { dataField: "", text: "Sl No", headerStyle: { width: "5%" } },
        { dataField: "date", text: "Date", formatter: dateFormatter },
        { dataField: "timeSlots", text: "Time Slots" },
        { dataField: "actions", text: "" }
    ];

    return (
        <div className="col-md-12">
            <DataGrid
                uniqueField="date"
                data={[props.interviewerSchedule]}
                columns={columnsInterviewerSchedule}
                disablePagination={true}
                filterRequired={false}
            />
        </div>
    );
};
export default InterviewerScheduleGridComponent;