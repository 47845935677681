import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import alertUtils from "../../../../utils/toaster-utils";
import RegisteredCandidatesGridComponent from "./RegisteredCandidatesGridComponent";
import RegisteredCandidatesSearchInputModel from "../../../../models/admin/candidate/request/RegisteredCandidatesSearchInputModel";
import RegisteredCandidatesModel from "../../../../models/admin/candidate/response/RegisteredCandidatesModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import IdNameModel from "../../../../models/common/IdNameModel";
import candidateService from "../../../../services/candidate/candidate-service";
import { FileUploadTypeEnum } from "../../../../models/enums/FileUploadTypeEnum";
import fileUtils from "../../../../utils/file-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import ScheduleInterviewPopUp from "../../../candidate/interview/ScheduleInterviewComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import modalUtils from "../../../../utils/modal-utils";
import PagedListModel from "../../../../models/common/PagedListModel";
import loaderUtils from "../../../../utils/loader-utils";

interface RegisteredCandidatesProps {
    primarySkillsList: IdNameModel[];
    timeSlotsList: IdNameModel[];
};

const RegisteredCandidatesComponent = (props: RegisteredCandidatesProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<RegisteredCandidatesModel>>();
    const [candidateList, setCandidateList] = useState<RegisteredCandidatesModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(new Date() as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);

    useEffect(() => {
        clearSearch();
    }, []);

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getCandidates = () => {
        setRefreshCandidate(2);
        var inputData: RegisteredCandidatesSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            primarySkillId: primarySKillSearch,
            name: "",
            emailId: "",
            page: 1,
            pageSize: 100,
            sort: "CandidateName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.getRegisteredCandidates(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);            
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setStartDateSearch("");
        setEndDateSearch("");
        setPrimarySkillSearch(0);
        setRefreshCandidate(2);
    };

    const onDownloadResumeActionHandler = (candidateId: string, candidateName: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        candidateService.downloadCandidateResume(candidateId, FileUploadTypeEnum.CandidateResume.toString()).then((res) => {
            modalUtils.closeModal();
            fileUtils.downloadFile(res, candidateName);            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onScheduleActionHandler =(candidateId: string) => {
        const config: GeneralPoupConfig = {
            title: "Add User",
            content: (
                <ScheduleInterviewPopUp
                    primarySkillsList={props.primarySkillsList}
                    timeSlotsList={props.timeSlotsList}
                    candidateId={candidateId}
                />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onVerifyActionHandler =(candidateId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCandidateService.verifyCandidate(candidateId).then((res) => {
            modalUtils.closeModal();
            if(res.data){
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }            
            getCandidates();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates()}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <RegisteredCandidatesGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        onDownloadResumeAction={onDownloadResumeActionHandler}
                        onScheduleAction={onScheduleActionHandler}
                        onVerifyAction={onVerifyActionHandler} />
                }
            </div>
        </div>
    );
};
export default RegisteredCandidatesComponent;