import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import IdNameModel from "../../models/common/IdNameModel";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import PagedListModel from "../../models/common/PagedListModel";
import AddProfilesRequestModel from "../../models/company/addProfiles/request/AddProfilesRequestModel";
import UserRegistrationResponse from "../../models/authentication/UserRegistrationResponse";
import AddProfileSearchInputModel from "../../models/company/addProfiles/request/AddProfileSearchInputModel";
import AddProfilesGridModel from "../../models/company/addProfiles/response/AddProfilesGridModel";
import AddProfilesStatusUpdateModel from "../../models/company/addProfiles/request/AddProfilesStatusUpdateModel";
import AddProfilesInterviewRoundsModel from "../../models/company/addProfiles/response/AddProfilesInterviewRoundsModel";
import ScheduleInterviewCompanyRequestModel from "../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import InterviewRatingModel from "../../models/interviewer/response/InterviewRatingModel";

class AddProfilesService {
    async fillVendorsForAddProfiles(companyId: string, requirementId: string) {
        let res: BaseResponseList<IdNameModel[]> = await httpClient.get<BaseResponseList<IdNameModel[]>>(AppConfig.company.addProfiles.fillVendorsForAddProfiles  + "/" + companyId + "/" + requirementId);
        return res;
    }

    async addRequirementProfile(profile: AddProfilesRequestModel) {
        let res: BaseResponse<UserRegistrationResponse> = await httpClient.post<BaseResponse<UserRegistrationResponse>>(AppConfig.company.addProfiles.addRequirementProfile, profile);
        return res;
    }

    async getAddProfilesGrid(inputParms: AddProfileSearchInputModel) {
        let res: PagedListModel<AddProfilesGridModel> = await httpClient.post<PagedListModel<AddProfilesGridModel>>(AppConfig.company.addProfiles.getAddProfilesGrid, inputParms);
        return res;
    }

    async updateProfileStatus(inputModel: AddProfilesStatusUpdateModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.addProfiles.updateProfileStatus, inputModel);
        return res;
    }

    async getRequirementInterviewRounds(requirementId: string, profileId: string) {
        let res: BaseResponseList<AddProfilesInterviewRoundsModel[]> = await httpClient.get<BaseResponseList<AddProfilesInterviewRoundsModel[]>>(AppConfig.company.addProfiles.getRequirementInterviewRounds  + "/" + requirementId + "/" + profileId);
        return res;
    }

    async scheduleInterview(inputModel: ScheduleInterviewCompanyRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.addProfiles.scheduleInterview, inputModel);
        return res;
    }

    async interviewRatingDetails(interviewId: string) {
        let res: BaseResponse<InterviewRatingModel> = await httpClient.get<BaseResponse<InterviewRatingModel>>(AppConfig.company.addProfiles.interviewRatingDetails  + "/" + interviewId);
        return res;
    }
}
const addProfilesService = new AddProfilesService();
export default addProfilesService;