import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import AddProfilesStatusUpdateModel from "../../../models/company/addProfiles/request/AddProfilesStatusUpdateModel";

interface StatusUpdateProps {
    objStatusUpdate: AddProfilesStatusUpdateModel;
    statusList: IdNameModel[];    
    onCloseStatusUpdate: () => void;
    onStatusUpdate: (objStatusUpdate: AddProfilesStatusUpdateModel) => void;
}

const StatusUpdateComponent = (props: StatusUpdateProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<AddProfilesStatusUpdateModel>({
        mode: "onChange",
        defaultValues: props.objStatusUpdate
    });

    const onSubmit = (formData: AddProfilesStatusUpdateModel) => {
        props.onStatusUpdate(formData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Vendor: </label>
                                    <select className="form-control form-select" {...register("statusId", { required: true })}>
                                        <option value="">Select Status</option>
                                        {props.statusList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.statusId && errors.statusId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Comments: </label>
                                    <textarea rows={3} className="form-control" {...register("comments", OBJECT_DESCRIPTION_RULE)} placeholder="Comments" autoComplete="off" />
                                    <span className="error">
                                        {errors.comments && errors.comments.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="row">
                        <div className="col-md-6">                            
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseStatusUpdate()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default StatusUpdateComponent;