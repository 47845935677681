import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import AddProfilesComponent from "../../components/company/add-profiles/AddProfilesComponent";
import JobPostingParams from "../../models/common/PageParms";
import { useParams } from "react-router-dom";
import masterDataService from "../../services/master-data-service";
import addProfilesService from "../../services/company/add-profiles-service";
import IdNameModel from "../../models/common/IdNameModel";
import alertUtils from "../../utils/toaster-utils";
import { ScreenEnum } from "../../models/enums/StatusEnum";
import companyClasses from "./company.module.css";
import jobPostingService from "../../services/company/job-posting-service";
import { useHistory } from "react-router";
import AppliedImage from "../../assets/InnerCSS/img/logos/candidate.png";
import RelatedImage from "../../assets/InnerCSS/img/logos/interviewer.png";
import ShortlistedImage from "../../assets/InnerCSS/img/logos/employer.png";
import { RoleEnum } from "../../models/enums/RoleEnum";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";

const AddProfiles = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();
    const requestParams: JobPostingParams = useParams();
    const [vendorList, setVendorList] = useState<IdNameModel[]>([]);
    const [statusList, setStatusList] = useState<IdNameModel[]>([]);
    const [refreshStatus, setRefreshStatus] = useState<number>(2);
    const [refreshVendors, setRefreshVendors] = useState<number>(2);
    const [jobCode, setJobCode] = useState<string>("");
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);

    useEffect(() => {
        if (loggedInUser) {
            getJobPosting(requestParams.requirementId);
            getVendorList(loggedInUser?.profileId, requestParams.requirementId);
            getStatusList();
        }
    }, []);

    const getJobPosting = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getJobPostingById(requirementId).then((res) => {
            modalUtils.closeModal();
            setJobCode(res.data.jobCode);            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const getVendorList = (companyId: string, requirementId: string) => {
        addProfilesService.fillVendorsForAddProfiles(companyId, requirementId).then((res) => {
            setVendorList(res.items);
            setRefreshVendors(refreshVendors * 5);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getStatusList = () => {
        masterDataService.loadStatusByScreen(ScreenEnum.AddProfileStatus).then((res) => {
            setStatusList(res.items);
            setRefreshStatus(refreshStatus * 5);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <div className="row">
                    <div className="col-md-2">
                        <h4 className="breadcrumb-header"> Add Profiles </h4>
                    </div>
                    <div className="col-md-2">
                        <h4 className="job-code-header" >{jobCode}</h4>
                    </div>
                    {roleId === parseInt(RoleEnum.ClientRecruiter.toString()) || roleId === parseInt(RoleEnum.ClientAdmin.toString()) &&
                        <div className="email-actions col-md-8">
                            <a className="btn" onClick={() => history.push("/related-profiles/" + requestParams.requirementId)}><span> <img src={RelatedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Related Candidate</b></a>
                            <a className="btn" onClick={() => history.push("/applied-candidates/" + requestParams.requirementId)}><span> <img src={AppliedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Applied Candidate</b></a>
                            <a className="btn" onClick={() => history.push("/shortlisted-profiles/" + requestParams.requirementId)}><span> <img src={ShortlistedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Shortlisted Candidate</b></a>
                        </div>
                    }
                    {roleId === parseInt(RoleEnum.VendorRecruiter.toString()) &&
                        <div className="email-actions col-md-8">
                            <a className="btn" ><span> <img src={RelatedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Related Candidate</b></a>
                            <a className="btn" ><span> <img src={AppliedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Applied Candidate</b></a>
                            <a className="btn" ><span> <img src={ShortlistedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Shortlisted Candidate</b></a>
                        </div>
                    }
                </div>
            </div>
            {refreshStatus > 2 && refreshVendors > 2 && loggedInUser &&
                <AddProfilesComponent
                    vendorList={vendorList}
                    statusList={statusList}
                    requirementId={requestParams.requirementId}
                    profileId={loggedInUser.profileId}
                    userId={loggedInUser.userId} />
            }
        </Layout>
    );
};
export default AddProfiles;