import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import vendorSubUserService from "../../../services/company/vendor-sub-user-service";
import VendorSubUserSearchInputModel from "../../../models/company/vendorUser/request/VendorSubUserSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import VendorSubUserPopUp from "./VendorSubUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import VendorSubUserGridComponent from "./VendorSubUserGridComponent";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import loaderUtils from "../../../utils/loader-utils";

const VendorSubUserComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorSubUserPagedList, setVendorSubUserPagedList] = useState<PagedListModel<VendorSubUserRequestModel>>();
    const [vendorSubUserList, setVendorSubUserList] = useState<VendorSubUserRequestModel[]>([]);
    const [refreshVendorSubUser, setRefreshVendorSubUser] = useState<number>(2);

    useEffect(() => {
        getVendorSubUsers();
    }, []);

    const getVendorSubUsers = () => {
        if (loggedInUser?.profileId) {
            var inputData: VendorSubUserSearchInputModel = {
                vendorId: loggedInUser?.profileId,
                vendorSubUserName: "",
                page: 1,
                pageSize: 100,
                sort: "VendorName",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            vendorSubUserService.getVendorSubUsers(inputData).then((res) => {
                modalUtils.closeModal();
                setVendorSubUserList([]);
                setVendorSubUserPagedList(res);
                setVendorSubUserList(res.items);
                setRefreshVendorSubUser(refreshVendorSubUser * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    const openVendorSubUserSaveModel = (vendorSubUserRequestModel: VendorSubUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Vendor User",
            content: (
                <VendorSubUserPopUp
                    objVendorSubUser={vendorSubUserRequestModel}
                    actionType={actionType}
                    onCloseVendorSubUser={onCloseVendorSubUserHandler}
                    onVendorSubUserSave={onVendorSubUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    const onCloseVendorSubUserHandler = () => {
        modalUtils.closeModal();
    }

    const onVendorSubUserSaveHandler = (objVendor: VendorSubUserRequestModel) => {
        setRefreshVendorSubUser(2);
        objVendor.vendorId = loggedInUser ? loggedInUser.profileId : GuidEnum.Empty;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        vendorSubUserService.saveVendorSubUser(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getVendorSubUsers();
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    }

    const onVendorSubUserActionHandler = (obj: VendorSubUserRequestModel, actionType: string) => {
        openVendorSubUserSaveModel(obj, actionType);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshVendorSubUser > 2 &&
                    <VendorSubUserGridComponent
                        key={refreshVendorSubUser}
                        vendorSubUserList={vendorSubUserList}
                        onVendorSubUserAction={onVendorSubUserActionHandler} />
                }
            </div>
        </div>
    );
};
export default VendorSubUserComponent;