import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import DesignationService from '../../../services/company/designation-service';
import DesignationSearchInputModel from "../../../models/company/designation/request/DesignationSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import DesignationModel from "../../../models/company/designation/response/DesignationModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import DesignationPopUp from "./DesignationCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import DesignationRequestModel from "../../../models/company/designation/request/DesignationRequestModel";
import DesignationGridComponent from "./DesignationGridComponent";
import loaderUtils from "../../../utils/loader-utils";

const DesignationComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [designationPagedList, setDesignationPagedList] = useState<PagedListModel<DesignationModel>>();
    const [designationList, setDesignationList] = useState<DesignationModel[]>([]);
    const [refreshDesignation, setRefreshDesignation] = useState<number>(2);

    useEffect(() => {
        getDesignations();
    }, []);

    const getDesignations = () => {
        if (loggedInUser?.profileId) {
            var inputData: DesignationSearchInputModel = {
                companyId: loggedInUser?.profileId,
                designationName: "",
                page: 1,
                pageSize: 100,
                sort: "DesignationName",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            DesignationService.getDesignations(inputData).then((res) => {
                modalUtils.closeModal();
                setDesignationList([]);
                setDesignationPagedList(res);
                setDesignationList(res.items);
                setRefreshDesignation(refreshDesignation * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    const openDesignationSaveModel = (designationRequestModel: DesignationRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Designation",
            content: (
                <DesignationPopUp
                    objDesignation={designationRequestModel}
                    actionType={actionType}
                    onCloseDesignation={onCloseDesignationHandler}
                    onDesignationSave={onDesignationSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    const onCloseDesignationHandler = () => {
        modalUtils.closeModal();
    }

    const onDesignationSaveHandler = (objDesignation: DesignationRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        DesignationService.saveDesignation(objDesignation).then((res) => {
            alertUtils.showSuccess(res.informationMessages.toString());
            modalUtils.closeModal();
            getDesignations();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    }

    const onDesignationActionHandler = (obj: DesignationRequestModel, actionType: string) => {
        openDesignationSaveModel(obj, actionType);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                {designationList.length > 0 && refreshDesignation > 2 &&
                    <DesignationGridComponent
                        key={refreshDesignation}
                        designationList={designationList}
                        onDesignationAction={onDesignationActionHandler} />
                }
            </div>
        </div>
    );
};
export default DesignationComponent;