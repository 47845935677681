import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminCompanyMappingModel from "../../../models/admin/dashboard/response/AdminCompanyMappingModel";
import AdminUserCompanyMappingRequestModel from "../../../models/admin/dashboard/request/AdminUserCompanyMappingRequestModel";
import DataGrid from "../../common/table/DataGrid";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface AdminUserProps {
    mappingList: AdminCompanyMappingModel[];
    adminId: string;
    companyList: IdNameModel[];
    onCloseMapping: () => void;
    onMappingSave: (objMapping: AdminUserCompanyMappingRequestModel) => void;
}

const AdminCompanyMappingComponent = (props: AdminUserProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<AdminUserCompanyMappingRequestModel>({
        mode: "onChange"
    });

    const onSubmit = (formData: AdminUserCompanyMappingRequestModel) => {
        formData.adminId = props.adminId;
        formData.userId = GuidEnum.Empty;
        props.onMappingSave(formData);
    }

    const columnsMapping = [
        { dataField: "adminName", text: "Admin Name" },
        { dataField: "companyName", text: "Company Name" }
    ];

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Company: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("companyId", { required: true })}>
                                        <option value="">Select Company</option>
                                        {props.companyList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.companyId && errors.companyId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <DataGrid
                            uniqueField="adminId"
                            data={props.mappingList}
                            columns={columnsMapping}
                            disablePagination={true}
                            filterRequired={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AdminCompanyMappingComponent;