import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../../context-store";
import PagedListModel from "../../../../models/common/PagedListModel";
import PrimarySkillModel from "../../../../models/admin/skill/response/PrimarySkillModel";
import adminSkillService from "../../../../services/admin/admin-skill-service";
import PrimarySkillSearchInputModel from "../../../../models/admin/skill/request/PrimarySkillSearchInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import PrimarySkillPopUp from "./PrimarySkillCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import PrimarySkillGridComponent from "./PrimarySkillGridComponent";
import PrimarySkillRequestModel from "../../../../models/admin/skill/request/PrimarySkillRequestModel";
import loaderUtils from "../../../../utils/loader-utils";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";
import { PageModeEnum } from "../../../../models/enums/PageModeEnum";

const PrimarySkillComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [primarySkillPagedList, setPrimarySkillPagedList] = useState<PagedListModel<PrimarySkillModel>>();
    const [primarySkillList, setPrimarySkillList] = useState<PrimarySkillModel[]>([]);
    const [refreshPrimarySkill, setRefreshPrimarySKill] = useState<number>(2);
    const [primarySkillFilter, setPrimarySkillFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("PrimarySkillName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getPrimarySkills(getSearchFilters(primarySkillFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (primarySkillFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: PrimarySkillSearchInputModel = {
            primarySkillName: primarySkillFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getPrimarySkills = (inputData: PrimarySkillSearchInputModel) => {
        console.log(inputData);
        setRefreshPrimarySKill(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminSkillService.getPrimarySkills(inputData).then((res) => {
            modalUtils.closeModal();
            setPrimarySkillList([]);
            setPrimarySkillPagedList(res);
            setPrimarySkillList(res.items);
            setRefreshPrimarySKill(refreshPrimarySkill * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPrimarySkillActionHandler = (obj: PrimarySkillRequestModel, actionType: string) => {
        openPrimarySkillSaveModel(obj, actionType);
    };

    const openPrimarySkillSaveModel = (primarySkillRequestModel: PrimarySkillRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Primary Skill",
            content: (
                <PrimarySkillPopUp
                    objPrimarySkill={primarySkillRequestModel}
                    actionType={actionType}
                    onClosePrimarySkill={onClosePrimarySkillHandler}
                    onPrimarySkillSave={onPrimarySkillSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onClosePrimarySkillHandler = () => {
        modalUtils.closeModal();
    };

    const onPrimarySkillSaveHandler = (objPrimarySkill: PrimarySkillRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.savePrimarySkill(objPrimarySkill).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getPrimarySkills(getSearchFilters(primarySkillFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getPrimarySkills(getSearchFilters(primarySkillFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getPrimarySkills(getSearchFilters(primarySkillFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getPrimarySkills(getSearchFilters(primarySkillFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const addPrimarySkill = () => {
        if (loggedInUser !== null) {
            let primarySkillRequestModel: PrimarySkillRequestModel = {
                primarySkillId: 0,
                primarySkillName: "",
                description: "",
                userId: loggedInUser?.userId
            }
            onPrimarySkillActionHandler(primarySkillRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
            <div className="cord-head cord-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getPrimarySkills(getSearchFilters(primarySkillFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addPrimarySkill}> + Add Primary Skill</a>
            </div>
            <div className="card-body">
                {primarySkillList.length > 0 && refreshPrimarySkill > 2 &&
                    <PrimarySkillGridComponent
                        key={refreshPrimarySkill}
                        primarySkillList={primarySkillList}
                        totalSize={primarySkillPagedList?.totalCount ? primarySkillPagedList?.totalCount : primarySkillList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        onPrimarySkillAction={onPrimarySkillActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler} />
                }
            </div>
        </div>
    );
};
export default PrimarySkillComponent;