import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../../models/common/PagedListModel";
import RegisteredCompaniesModel from "../../../../models/admin/company/response/RegisteredCompaniesModel";
import RegisteredCompaniesSeachInputModel from "../../../../models/admin/company/request/RegisteredCompaniesSeachInputModel";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import alertUtils from "../../../../utils/toaster-utils";
import CompanyOnBoardGridComponent from "./CompanyOnBoardGridComponent";
import CompanyConfigurationPopUp from "./CompanyConfigurationComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import RegisteredCompaniesRequestModel from "../../../../models/admin/company/request/RegisteredCompaniesRequestModel";

const CompanyOnBoardComponent = () => {
    const [companyPagedList, setCompanyPagedList] = useState<PagedListModel<RegisteredCompaniesModel>>();
    const [companyList, setCompanyList] = useState<RegisteredCompaniesModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);

    useEffect(() => {
        getRegisteredCompanies();
    }, []);

    const getRegisteredCompanies = () => {
        setRefreshCompany(2);
        var inputData: RegisteredCompaniesSeachInputModel = {
            companyName: "",
            emailId: "",
            page: 1,
            pageSize: 100,
            sort: "CompanyName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getRegisteredCompanies(inputData).then((res) => {
            modalUtils.closeModal();
            setCompanyList([]);
            setCompanyPagedList(res);
            setCompanyList(res.items);
            setRefreshCompany(refreshCompany * 5);
        }).catch((error) => {
            setRefreshCompany(refreshCompany * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onEditConfigurationActionHandler = (companyId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getCompanyConfigurationById(companyId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Configuration",
                content: (
                    <CompanyConfigurationPopUp
                        objCompany={res.data}
                        onCloseCompanyConfiguration={onCloseCompanyConfigurationHandler}
                        onCompanyConfigurationSave={onCompanyConfigurationSaveHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseCompanyConfigurationHandler = () => {
        modalUtils.closeModal();
    };

    const onCompanyConfigurationSaveHandler = (objCompany: RegisteredCompaniesRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCompanyService.updateCompanyCofiguration(objCompany).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());            
            getRegisteredCompanies();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {companyList.length > 0 && refreshCompany > 2 &&
                    <CompanyOnBoardGridComponent
                        key={refreshCompany}
                        companyList={companyList}
                        onEditConfigurationAction={onEditConfigurationActionHandler} />
                }
            </div>
        </div>
    );
};
export default CompanyOnBoardComponent;