import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import RelatedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/RelatedProfilesGridModel";
import RelatedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import companyClasses from "../../../landing-pages/company/company.module.css";

interface RelatedProfilesGridProps {
    relatedProfilesList: RelatedProfilesGridModel[];
    requirementId: string;
    onShortlistProfile: (row: RelatedProfilesRequestModel) => void;
    onRatingDetails: (row: RelatedProfilesRequestModel, hasAccess: boolean) => void;
};

const RelatedProfilesGridComponent = (props: RelatedProfilesGridProps) => {

    const shortlistCandidate = (objProfile: RelatedProfilesGridModel) => {
        let updateModel: RelatedProfilesRequestModel = {
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onShortlistProfile(updateModel);
    };

    const viewRating = (objProfile: RelatedProfilesGridModel) => {
        let updateModel: RelatedProfilesRequestModel = {
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onRatingDetails(updateModel, objProfile.restrictEmployerToViewProfile);
    };

    return (
        <div className="row">
            {props.relatedProfilesList.map((profile: RelatedProfilesGridModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{profile.candidateName} </h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-3">
                                    <p><b>Exp </b>: {profile.experience}</p>
                                    <p><b>Notice Period</b>: {profile.noticePeriod}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Interview Date</b>: {profile.interviewDate}</p>
                                    <p><b>Rating</b>: {profile.totalRating}</p>
                                </div>
                                <div className="col-md-3">
                                    <div className="input-group-btn input-group-prepend last">
                                        <ul className="list-inline ">
                                            <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => viewRating(profile)}> Rating</a></h6></li>
                                            <button className="btn btn-warning" type="button" onClick={() => shortlistCandidate(profile)}> ShortList</button>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
export default RelatedProfilesGridComponent;