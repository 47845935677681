import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import SecondarySkillPopUp from "./SecondarySkillCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import SecondarySkillGridComponent from "./SecondarySkillGridComponent";
import PrimarySkillRequestModel from "../../../../models/admin/skill/request/PrimarySkillRequestModel";
import SecondarySkillModel from "../../../../models/admin/skill/response/SecondarySkillModel";
import SecondarySkillSearchInputModel from "../../../../models/admin/skill/request/SecondarySkillSearchInputModel";
import adminSkillService from "../../../../services/admin/admin-skill-service";
import SecondarySkillRequestModel from "../../../../models/admin/skill/request/SecondarySkillRequestModel";
import loaderUtils from "../../../../utils/loader-utils";

interface SecondarySkillsProps {
    primarySkillsList: IdNameModel[];
};

const SecondarySkillComponent = (props: SecondarySkillsProps) => {
    const [secondarySkillPagedList, setSecondarySkillPagedList] = useState<PagedListModel<SecondarySkillModel>>();
    const [secondarySkillList, setSecondarySkillList] = useState<SecondarySkillModel[]>([]);
    const [refreshSecondarySkill, setRefreshSecondarySKill] = useState<number>(2);

    useEffect(() => {
        getSecondarySkills();
    }, []);

    const getSecondarySkills = () => {
        setRefreshSecondarySKill(2);
        var inputData: SecondarySkillSearchInputModel = {
            secondarySkillName: "",
            primarySkillName: "",
            page: 1,
            pageSize: 100,
            sort: "SecondarySkillName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminSkillService.getSecondarySkills(inputData).then((res) => {
            modalUtils.closeModal();
            setSecondarySkillList([]);
            setSecondarySkillPagedList(res);
            setSecondarySkillList(res.items);
            setRefreshSecondarySKill(refreshSecondarySkill * 5);            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onSecondarySkillActionHandler = (obj: SecondarySkillRequestModel, actionType: string) => {
        openSecondarySkillSaveModel(obj, actionType);
    };

    const openSecondarySkillSaveModel = (secondarySkillRequestModel: SecondarySkillRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Secondary Skill",
            content: (
                <SecondarySkillPopUp
                    objSecondarySkill={secondarySkillRequestModel}
                    primarySkillList={props.primarySkillsList}
                    actionType={actionType}
                    onCloseSecondarySkill={onCloseSecondarySkillHandler}
                    onSecondarySkillSave={onSecondarySkillSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseSecondarySkillHandler = () => {
        modalUtils.closeModal();
    };

    const onSecondarySkillSaveHandler = (objSecondarySkill: SecondarySkillRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.saveSecondarySkill(objSecondarySkill).then((res) => {            
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());            
            getSecondarySkills();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    }

    return (
        <div className="card card-white">
        <div className="card-body">
            {secondarySkillList.length > 0 && refreshSecondarySkill > 2 &&
                <SecondarySkillGridComponent
                    key={refreshSecondarySkill}
                    secondarySkillList={secondarySkillList}
                    onSecondarySkillAction={onSecondarySkillActionHandler} />
            }
        </div>
    </div>
    );
};
export default SecondarySkillComponent;