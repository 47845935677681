import { useState, useEffect } from "react";
import CandidateRelatedJobRequirementsGridModel from "../../../models/candidate/response/CandidateRelatedJobRequirementsGridModel";

interface CandidateJobGridProps {
    requirementList: CandidateRelatedJobRequirementsGridModel[];
}

const CandidateAppliedJobsGridComponent = (props: CandidateJobGridProps) => {
    return (
        <div className="row">
            {props.requirementList.map((requirement: CandidateRelatedJobRequirementsGridModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{requirement.jobCode}</h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Exp </b>: {requirement.experience}</p>
                                    <p><b>Designation</b>: {requirement.designationName}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Primary Skill</b>: {requirement.primarySkillName}</p>
                                    <p><b>Skills</b>: {requirement.secondarySkills}</p>
                                </div>
                                <div className="col-md-4">
                                    <p><b>Location</b>: {requirement.cityName}</p>
                                    <p><b>Job Type</b>: {requirement.jobTypeName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
export default CandidateAppliedJobsGridComponent;