import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import PagedListModel from "../../models/common/PagedListModel";
import PrimarySkillSearchInputModel from "../../models/admin/skill/request/PrimarySkillSearchInputModel";
import PrimarySkillRequestModel from "../../models/admin/skill/request/PrimarySkillRequestModel";
import PrimarySkillModel from "../../models/admin/skill/response/PrimarySkillModel";
import SecondarySkillSearchInputModel from "../../models/admin/skill/request/SecondarySkillSearchInputModel";
import SecondarySkillModel from "../../models/admin/skill/response/SecondarySkillModel";
import SecondarySkillRequestModel from "../../models/admin/skill/request/SecondarySkillRequestModel";

class AdminSkillService {
    async getPrimarySkills(inputParms: PrimarySkillSearchInputModel) {
        let res: PagedListModel<PrimarySkillModel> = await httpClient.post<PagedListModel<PrimarySkillModel>>(AppConfig.admin.skills.getPrimarySkills, inputParms);
        return res;
    }

    async getPrimarySkillById(skillId: string) {
        let res: BaseResponse<PrimarySkillModel> = await httpClient.get<BaseResponse<PrimarySkillModel>>(AppConfig.admin.skills.getPrimarySkillById  + "/" + skillId);
        return res;
    }

    async savePrimarySkill(skill: PrimarySkillRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.skills.savePrimarySkill, skill);
        return res;
    }

    async getSecondarySkills(inputParms: SecondarySkillSearchInputModel) {
        let res: PagedListModel<SecondarySkillModel> = await httpClient.post<PagedListModel<SecondarySkillModel>>(AppConfig.admin.skills.getSecondarySkills, inputParms);
        return res;
    }

    async getSecondarySkillById(skillId: string) {
        let res: BaseResponse<SecondarySkillModel> = await httpClient.get<BaseResponse<SecondarySkillModel>>(AppConfig.admin.skills.getSecondarySkillById  + "/" + skillId);
        return res;
    }

    async saveSecondarySkill(skill: SecondarySkillRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.skills.saveSecondarySkill, skill);
        return res;
    }
}
const adminSkillService = new AdminSkillService();
export default adminSkillService;