import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import DepartmentService from '../../../services/company/department-service';
import DepartmentSearchInputModel from "../../../models/company/department/request/DepartmentSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import DepartmentModel from "../../../models/company/department/response/DepartmentModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import DepartmentPopUp from "./DepartmentCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import DepartmentRequestModel from "../../../models/company/department/request/DepartmentRequestModel";
import DepartmentGridComponent from "./DepartmentGridComponent";
import loaderUtils from "../../../utils/loader-utils";

const DepartmentComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [departmentPagedList, setDepartmentPagedList] = useState<PagedListModel<DepartmentModel>>();
    const [departmentList, setDepartmentList] = useState<DepartmentModel[]>([]);
    const [refreshDepartment, setRefreshDepartment] = useState<number>(2);

    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = () => {
        if (loggedInUser?.profileId) {
            var inputData: DepartmentSearchInputModel = {
                companyId: loggedInUser?.profileId,
                departmentName: "",
                page: 1,
                pageSize: 100,
                sort: "DepartmentName",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            DepartmentService.getDepartments(inputData).then((res) => {
                modalUtils.closeModal();
                setDepartmentList([]);
                setDepartmentPagedList(res);
                setDepartmentList(res.items);
                setRefreshDepartment(refreshDepartment * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    const openDepartmentSaveModel = (departmentRequestModel: DepartmentRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Department",
            content: (
                <DepartmentPopUp
                    objDepartment={departmentRequestModel}
                    actionType={actionType}
                    onCloseDepartment={onCloseDepartmentHandler}
                    onDepartmentSave={onDepartmentSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    const onCloseDepartmentHandler = () => {
        modalUtils.closeModal();
    }

    const onDepartmentSaveHandler = (objDepartment: DepartmentRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        DepartmentService.saveDepartment(objDepartment).then((res) => {            
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());            
            getDepartments();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    }

    const onDepartmentActionHandler = (obj: DepartmentRequestModel, actionType: string) => {
        openDepartmentSaveModel(obj, actionType);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                {departmentList.length > 0 && refreshDepartment > 2 &&
                    <DepartmentGridComponent
                        key={refreshDepartment}
                        departmentList={departmentList}
                        onDepartmentAction={onDepartmentActionHandler} />
                }
            </div>
        </div>
    );
};
export default DepartmentComponent;