import React from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface VendorSubUserGridProps {
    vendorSubUserList: VendorSubUserRequestModel[];
    onVendorSubUserAction: (row: VendorSubUserRequestModel, actionType: string) => void;
}

const VendorSubUserGridComponent = (props: VendorSubUserGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);

    const editVendor = (row: VendorSubUserRequestModel) => {
        if (loggedInUser !== null) {
            let vendorSubUserRequestModel: VendorSubUserRequestModel = {
                vendorSubUserId: row.vendorSubUserId,
                vendorId: row.vendorId,
                userId: row.userId,
                vendorName: row.vendorName,
                vendorEmailId: row.vendorEmailId,
                vendorUserName: row.vendorUserName,
                vendorPhoneNumber: row.vendorPhoneNumber
            }
            props.onVendorSubUserAction(vendorSubUserRequestModel, PageModeEnum.Edit);
        }
    }

    const addVendor = () => {
        if (loggedInUser !== null) {
            let vendorSubUserRequestModel: VendorSubUserRequestModel = {
                vendorSubUserId: GuidEnum.Empty,
                vendorId: "",
                userId: GuidEnum.Empty,
                vendorName: "",
                vendorEmailId: "",
                vendorUserName: "",
                vendorPhoneNumber: null
            }
            props.onVendorSubUserAction(vendorSubUserRequestModel, PageModeEnum.Create);
        }
    }

    const columnsVendorSubUsers = [
        { dataField: "vendorName", text: "Vendor SubUser Name" },
        { dataField: "vendorEmailId", text: "Email" },
        { dataField: "vendorPhoneNumber", text: "Mobile No" }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addVendor}> + Add Vendor</button>
                <DataGrid
                    uniqueField="VendorSubUserId"
                    data={props.vendorSubUserList}
                    columns={columnsVendorSubUsers}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default VendorSubUserGridComponent;