import React from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import DesignationModel from "../../../models/company/designation/response/DesignationModel";
import DesignationRequestModel from "../../../models/company/designation/request/DesignationRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface DesignationGridProps {
    designationList: DesignationModel[];
    onDesignationAction: (row: DesignationRequestModel, actionType: string) => void;
}

const DesignationGridComponent = (props: DesignationGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: DesignationModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editDesignation(row); }}>Edit</a>
            </div>
        );
    };

    const editDesignation = (row: DesignationModel) => {
        if (loggedInUser !== null) {
            let designationRequestModel: DesignationRequestModel = {
                designationId: row.designationId,
                designationName: row.designationName,
                description: row.description,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onDesignationAction(designationRequestModel, PageModeEnum.Edit);
        }
    }

    const addDesignation = () => {
        if (loggedInUser !== null) {
            let designationRequestModel: DesignationRequestModel = {
                designationId: GuidEnum.Empty,
                designationName: "",
                description: "",
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onDesignationAction(designationRequestModel, PageModeEnum.Create);
        }
    }

    const columnsDesignations = [
        { dataField: "designationName", text: "Designation Name" },
        { dataField: "description", text: "Description" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addDesignation}> + Add Designation</button>
                <DataGrid
                    uniqueField="designationId"
                    data={props.designationList}
                    columns={columnsDesignations}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default DesignationGridComponent;