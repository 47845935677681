import React from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import VendorRequestModel from "../../../models/company/vendor/request/VendorRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface VendorGridProps {
    vendorList: VendorRequestModel[];
    onVendorAction: (row: VendorRequestModel, actionType: string) => void;
}

const VendorGridComponent = (props: VendorGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);

    const editVendor = (row: VendorRequestModel) => {
        if (loggedInUser !== null) {
            let vendorRequestModel: VendorRequestModel = {
                vendorId: row.vendorId,
                userId: row.userId,
                companyId: loggedInUser?.profileId,
                vendorName: row.vendorName,
                vendorEmailId: row.vendorEmailId,
                vendorUserName: row.vendorUserName,
                vendorPhoneNumber: row.vendorPhoneNumber,
                startDate: row.startDate,
                endDate: row.endDate
            }
            props.onVendorAction(vendorRequestModel, PageModeEnum.Edit);
        }
    }

    const addVendor = () => {
        if (loggedInUser !== null) {
            let vendorRequestModel: VendorRequestModel = {
                vendorId: GuidEnum.Empty,
                userId: "",
                companyId: loggedInUser?.profileId,
                vendorName: "",
                vendorEmailId: "",
                vendorUserName: "",
                vendorPhoneNumber: null,
                startDate: null,
                endDate: null
            }
            props.onVendorAction(vendorRequestModel, PageModeEnum.Create);
        }
    }

    const columnsVendors = [
        { dataField: "vendorName", text: "Vendor Name" },
        { dataField: "vendorEmailId", text: "Vendor Email" },
        { dataField: "vendorPhoneNumber", text: "Vendor Phone No" },
        { dataField: "startDate", text: "Start Date" },
        { dataField: "endDate", text: "End Date" }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addVendor}> + Add Vendor</button>
                <DataGrid
                    uniqueField="vendorId"
                    data={props.vendorList}
                    columns={columnsVendors}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default VendorGridComponent;