import { useState, useEffect } from "react";
import AddProfilesGridModel from "../../../models/company/addProfiles/response/AddProfilesGridModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import DataGrid from "../../common/table/DataGrid";
import IdNameModel from "../../../models/common/IdNameModel";
import addProfilesService from "../../../services/company/add-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import InterviewRoundsPopUp from "./ScheduleInterviewRoundsComponent";
import ScheduleInterviewPopUp from "./ScheduleInterviewComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import AddProfilesInterviewRoundsModel from "../../../models/company/addProfiles/response/AddProfilesInterviewRoundsModel";
import modalUtils from "../../../utils/modal-utils";
import ScheduleInterviewCompanyRequestModel from "../../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import ScheduleInterviewRequest from "../../../models/candidate/request/ScheduleInterviewRequest";
import { InterviewTypeEnum } from "../../../models/enums/InterviewTypeEnum";
import { useAppSelector } from "../../../context-store";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import loaderUtils from "../../../utils/loader-utils";

interface AddProfilesGridProps {
    addProfilesList: AddProfilesGridModel[];
    requirementId: string;
    timeSlotsList: IdNameModel[];
    onProfileAdd: (actionType: string) => void;
    onStatusUpdate: (row: AddProfilesGridModel) => void;
    onScheduleInterview: (row: ScheduleInterviewCompanyRequestModel) => void;
    onRatingDetails: (interviewId: string) => void;
}

const AddProfilesGridComponent = (props: AddProfilesGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);
    const ActionFormatter = (
        cell: any,
        row: AddProfilesGridModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                {(roleId === parseInt(RoleEnum.ClientRecruiter.toString()) || roleId === parseInt(RoleEnum.Administrator.toString()) 
                || roleId === parseInt(RoleEnum.IAIRecruiter.toString())) &&                    
                <div>
                    <a onClick={() => { statusUpdate(row); }}>Update Status</a>
                    <a onClick={() => { scheduleInterview(row); }}>Schedule Interview</a>
                </div>
                }
            </div>
        );
    };

    const statusUpdate = (row: AddProfilesGridModel) => {
        props.onStatusUpdate(row);
    };

    const openInterviewRoundsModel = (interviewRounds: AddProfilesInterviewRoundsModel[], objAddProfile: AddProfilesGridModel) => {
        const config: GeneralPoupConfig = {
            title: "Schedule Interview Rounds",
            content: (
                <InterviewRoundsPopUp
                    objAddProfile={objAddProfile}
                    interviewRoundsList={interviewRounds}
                    onCloseInterviewRounds={onCloseInterviewRoundsHandler}
                    onInterviewRoundSelect={onInterviewRoundSelectHandler}
                    onInterviewRatingDetails={onInterviewRatingDetailsHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseInterviewRoundsHandler = () => {
        modalUtils.closeModal();
    };

    const onInterviewRoundSelectHandler = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        openScheduleInterviewModel(objInterviewRound, objAddProfile);
    };

    const onInterviewRatingDetailsHandler = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        props.onRatingDetails(objInterviewRound.interviewId);
    };

    const openScheduleInterviewModel = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        let scheduleInterview: ScheduleInterviewRequest = {
            candidateId: objAddProfile.candidateId,
            interviewDate: new Date(),
            timeSlotId: 0,
            interviewTypeId: InterviewTypeEnum.Audio,
            primarySkillId: 0,
            secondarySkills: [],
            termsAndConditions: true,
            requirementId: objAddProfile.requirementId,
            interviewId: objInterviewRound.interviewId
        };
        let objScheduleInterview: ScheduleInterviewCompanyRequestModel = {
            profileId: objAddProfile.profileId,
            userId: GuidEnum.Empty,
            requirementInterviewProcessId: objInterviewRound.requirementInterviewProcessId,
            requirementScheduleId: objInterviewRound.requirementScheduleId,
            interviewId: objInterviewRound.interviewId,
            ScheduleInterviewRequestModel: scheduleInterview
        }
        const config: GeneralPoupConfig = {
            title: objInterviewRound.interviewId === null ? "Schedule Interview" : "Re Schedule Interview",
            content: (
                <ScheduleInterviewPopUp
                    objScheduleInterview={objScheduleInterview}
                    timeSlotsList={props.timeSlotsList}
                    onCloseScheduleInterview={onCloseScheduleInterviewHandler}
                    onScheduleInterviewSave={onScheduleInterviewSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseScheduleInterviewHandler = () => {
        modalUtils.closeModal();
    };

    const onScheduleInterviewSaveHandler = (objScheduleInterview: ScheduleInterviewCompanyRequestModel) => {
        props.onScheduleInterview(objScheduleInterview);
    };

    const scheduleInterview = (row: AddProfilesGridModel) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.getRequirementInterviewRounds(row.requirementId, row.profileId).then((res) => {
            modalUtils.closeModal();
            if (res.items.length > 0) {
                //if (row.addInterviewProcess) {
                openInterviewRoundsModel(res.items, row);
                // } else {
                //     openScheduleInterviewModel(res.items[0], row);
                // }
            } else {
                alertUtils.showError("No Data Found");
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const addCandidate = (actionType: string) => {
        props.onProfileAdd(actionType);
    };

    const columnsAddProfile = [
        { dataField: "candidateName", text: "Candidate Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No" },
        { dataField: "comments", text: "Comments" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={() => addCandidate(PageModeEnum.Create)}> + Add Candidate</button>
                <DataGrid
                    uniqueField="emailId"
                    data={props.addProfilesList}
                    columns={columnsAddProfile}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default AddProfilesGridComponent;