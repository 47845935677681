import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import { useParams } from "react-router-dom";
import JobPostingParams from "../../models/common/PageParms";
import JobPostingInterviewProcessComponent from "../../components/company/job-posting-interview-process/JobPostingInterviewProcessComponent";
import CompanyConfigurationModel from "../../models/company/CompanyConfigurationModel";
import JobPostingRequestModel from "../../models/company/jobPosting/request/JobPostingRequestModel";
import { GuidEnum } from "../../models/enums/GuidEnum";
import IdNameModel from "../../models/common/IdNameModel";
import jobPostingService from "../../services/company/job-posting-service";
import alertUtils from "../../utils/toaster-utils";
import masterDataService from "../../services/master-data-service";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";

var companyConfigurationDefault: CompanyConfigurationModel = {
    companyId: "",
    countryId: 1
};

var jobPostingRequestModelDefault: JobPostingRequestModel = {
    addInterviewProcess: false,
    additionalSkills: "",
    assessmentName: "",
    captureScreenShot: true,
    cityId: null,
    companyId: GuidEnum.Empty,
    designationId: GuidEnum.Empty,
    domainId: null,
    endDate: new Date(),
    startDate: new Date(),
    highestPay: "",
    interviewTypeId: null,
    jobCode: "",
    jobDescription: "",
    jobPostingSkillRequestModel: [],
    jobTypeId: null,
    keyResponsibilities: "",
    maxExperienceId: null,
    minExperienceId: null,
    minRatingForSelection: 0,
    primarySkillId: null,
    recruiterId: GuidEnum.Empty,
    requirementId: GuidEnum.Empty,
    scheduleZoomInterview: true,
    sendMailToRecruiter: true,
    statusId: null,
    userId: GuidEnum.Empty
};

const JobPostingInterviewProcess = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const requestParams: JobPostingParams = useParams();
    const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationModel>(companyConfigurationDefault);
    const [jobPostingRequestModel, setJobPostingRequestModel] = useState<JobPostingRequestModel>(jobPostingRequestModelDefault);
    const [secondarySkillList, setSecondarySkillList] = useState<IdNameModel[]>([]);
    const [interviewRoundList, setSnterviewRoundList] = useState<IdNameModel[]>([]);

    useEffect(() => {
        getCompanyConfiguration();
        getJobPostingById(requestParams.requirementId);
        loadInterviewRounds();
    }, []);

    const getCompanyConfiguration = () => {
        if (loggedInUser) {
            jobPostingService.getCompanyConfiguration(loggedInUser.userId).then((res) => {
                setCompanyConfiguration(res.data);
            }).catch((error) => {
                alertUtils.showError(error);
            });
        }
    };

    const getJobPostingById = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getJobPostingById(requirementId).then((res) => {
            modalUtils.closeModal();
            setJobPostingRequestModel(res.data);
            loadSecondarySkills(res.data.primarySkillId);            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const loadSecondarySkills = (primarySkillId: number | null) => {
        if (primarySkillId !== 0 && primarySkillId !== null) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            masterDataService.loadSecondarySkills(primarySkillId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    setSecondarySkillList(res.items);
                } else {
                    alertUtils.showError("Unable to load Primary skills");
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Primary skills");
            });
        } else {
            alertUtils.showError("Please Select Primary Skill");
        }
    };

    const loadInterviewRounds = () => {
        masterDataService.loadInterviewRounds().then((res) => {
            setSnterviewRoundList(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Interview Process </h4>
            </div>
            {secondarySkillList.length > 0 && interviewRoundList.length > 0 &&
                <JobPostingInterviewProcessComponent
                    companyConfiguration={companyConfiguration}
                    jobPostingRequestModel={jobPostingRequestModel}
                    secondarySkillList={secondarySkillList}
                    interviewRoundList={interviewRoundList} />
            }
        </Layout>
    );
};
export default JobPostingInterviewProcess;