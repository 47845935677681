import React from "react";
import { Redirect, useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingModel from "../../../models/company/jobPosting/response/JobPostingModel";
import { Nav, NavDropdown } from "react-bootstrap";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import { InterviewTypeEnum } from "../../../models/enums/InterviewTypeEnum";

interface JobPostingGridProps {
    jobPostingList: JobPostingModel[];
    onJobPostingAction: (row: JobPostingRequestModel, actionType: string) => void;
    onJobPostingVendorAction: (requirementId: string) => void;
}

const JobPostingGridComponent = (props: JobPostingGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();

    const ActionFormatter = (
        cell: any,
        row: JobPostingModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a style={{ display: 'block' }} onClick={() => { editJobPosting(row); }}>Edit</a>
                {row.addInterviewProcess &&
                    <a style={{ display: 'block' }} onClick={() => { setInterviewProcess(row); }}>InterView Process</a>
                }
                <a style={{ display: 'block' }} onClick={() => { props.onJobPostingVendorAction(row.requirementId); }}>Add Vendors</a>
            </div>
        );
    };

    const editJobPosting = (row: JobPostingModel) => {
        if (loggedInUser !== null) {
            let jobPostingRequestModel: JobPostingRequestModel = {
                requirementId: row.requirementId,
                companyId: row.companyId,
                recruiterId: loggedInUser.profileId,
                jobTypeId: null,
                jobCode: row.jobCode,
                jobDescription: "",
                keyResponsibilities: "",
                domainId: null,
                additionalSkills: "",
                primarySkillId: null,
                minExperienceId: null,
                maxExperienceId: null,
                startDate: new Date(),
                endDate: new Date,
                cityId: null,
                designationId: "",
                highestPay: "",
                interviewTypeId: null,
                assessmentName: "",
                scheduleZoomInterview: true,
                addInterviewProcess: false,
                statusId: null,
                sendMailToRecruiter: true,
                captureScreenShot: true,
                minRatingForSelection: 0,
                jobPostingSkillRequestModel: [],
                userId: loggedInUser.userId
            }
            props.onJobPostingAction(jobPostingRequestModel, PageModeEnum.Edit);
        }
    };

    const setInterviewProcess = (row: JobPostingModel) => {
        history.push("/interview-process/" + row.requirementId);
    }

    const addJobPosting = () => {
        if (loggedInUser !== null) {
            let baseSkillDetails: JobPostingSkillRequestModel[] = [];
            let minSkills: number = 3;
            for (let i = 1; i <= minSkills; i++) {
                let skill: JobPostingSkillRequestModel = {
                    requirementSkillId: GuidEnum.Empty,
                    requirementId: GuidEnum.Empty,
                    secondarySkillId: null,
                    secondarySkillNumber: i,
                    isCommentsRequired: true,
                    requirementInterviewProcessId: GuidEnum.Empty,
                    recruiterId: GuidEnum.Empty,
                    userId: GuidEnum.Empty
                };
                baseSkillDetails.push(skill);
            }
            let jobPostingRequestModel: JobPostingRequestModel = {
                requirementId: GuidEnum.Empty,
                companyId: loggedInUser.profileId,
                recruiterId: loggedInUser.profileId,
                jobTypeId: null,
                jobCode: "",
                jobDescription: "",
                keyResponsibilities: "",
                domainId: null,
                additionalSkills: "",
                primarySkillId: null,
                minExperienceId: null,
                maxExperienceId: null,
                startDate: new Date(),
                endDate: new Date,
                cityId: null,
                designationId: "",
                highestPay: "",
                interviewTypeId: parseInt(InterviewTypeEnum.Video.toString()),
                assessmentName: "",
                scheduleZoomInterview: true,
                addInterviewProcess: false,
                statusId: null,
                sendMailToRecruiter: true,
                captureScreenShot: true,
                minRatingForSelection: 0,
                jobPostingSkillRequestModel: baseSkillDetails,
                userId: loggedInUser.userId
            }
            props.onJobPostingAction(jobPostingRequestModel, PageModeEnum.Create);
        }
    }

    const columnsJobPostings = [
        { dataField: "jobCode", text: "Job Code" },
        { dataField: "jobTitle", text: "Job Title" },
        { dataField: "jobTypeName", text: "Job Type" },
        { dataField: "minExperience", text: "Min Exp" },
        { dataField: "maxExperience", text: "Max Exp" },
        { dataField: "highestPay", text: "Highest Pay" },
        { dataField: "startDate", text: "Posting St Dt" },
        { dataField: "endDate", text: "Posting End Dt" },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "status", text: "Status" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <button className="btn btn-default float-right" onClick={addJobPosting}> + Add JobPosting</button>
                <DataGrid
                    uniqueField="requirementId"
                    data={props.jobPostingList}
                    columns={columnsJobPostings}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default JobPostingGridComponent;