export enum RoleEnum {
    Administrator = 1,
    Candidate = 2,
    Interviewer = 3,
    IAIRecruiter = 4,
    Vendor = 5,
    ClientInterviewer = 6,
    ClientAdmin = 7,
    ClientRecruiter = 8,
    PanelManagement = 9,
    CommonComponents = 10,
    VendorRecruiter = 11    
}