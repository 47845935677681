import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import jobPostingService from "../../../services/company/job-posting-service";
import JobPostingSearchInputModel from "../../../models/company/jobPosting/request/JobPostingSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import JobPostingPopUp from "./JobPostingCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import JobPostingGridComponent from "./JobPostingGridComponent";
import JobPostingModel from "../../../models/company/jobPosting/response/JobPostingModel";
import IdNameModel from "../../../models/common/IdNameModel";
import CompanyConfigurationModel from "../../../models/company/CompanyConfigurationModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import JobPostingVendorPopUp from "./JobPostingVendorsComponent";
import JobPostingVendorRequestModel from "../../../models/company/jobPosting/request/JobPostingVendorRequestModel";
import loaderUtils from "../../../utils/loader-utils";

interface JobPostingProps {
    companyConfiguration: CompanyConfigurationModel;
    jobTypes: IdNameModel[];
    assessmentTypes: IdNameModel[];
    domains: IdNameModel[];
    primarySkills: IdNameModel[];
    experiences: IdNameModel[];
    cities: IdNameModel[];
    designations: IdNameModel[];
    salaries: IdNameModel[];
    profileId: string;
    userId: string;
}

const JobPostingComponent = (props: JobPostingProps) => {
    //let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [jobPostingPagedList, setJobPostingPagedList] = useState<PagedListModel<JobPostingModel>>();
    const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationModel>(props.companyConfiguration);
    const [jobPostingList, setJobPostingList] = useState<JobPostingModel[]>([]);
    const [jobTypes, setJobTypes] = useState<IdNameModel[]>(props.jobTypes);
    const [assessmentTypes, setAssessmentTypes] = useState<IdNameModel[]>(props.assessmentTypes);
    const [domains, setDomains] = useState<IdNameModel[]>(props.domains);
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>(props.primarySkills);
    const [experiences, setExperiences] = useState<IdNameModel[]>(props.experiences);
    const [cities, setCities] = useState<IdNameModel[]>(props.cities);
    const [designations, setDesignations] = useState<IdNameModel[]>(props.designations);
    const [salaries, setSalaries] = useState<IdNameModel[]>(props.salaries);
    const [refreshJobPosting, setRefreshJobPosting] = useState<number>(2);
    console.log(companyConfiguration);
    useEffect(() => {
        getJobPostings();
    }, []);

    const getJobPostings = () => {
        var inputData: JobPostingSearchInputModel = {
            userId: props.profileId,
            companyId: props.profileId,
            jobCode: "",
            statusId: null,
            primarySkillId: null,
            page: 1,
            pageSize: 100,
            sort: "JobCode",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getJobPostings(inputData).then((res) => {
            modalUtils.closeModal();
            setJobPostingList([]);
            setJobPostingPagedList(res);
            setJobPostingList(res.items);
            setRefreshJobPosting(refreshJobPosting * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const openJobPostingSaveModel = (jobPostingRequestModel: JobPostingRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add JobPosting",
            content: (
                <JobPostingPopUp
                    objJobPosting={jobPostingRequestModel}
                    actionType={actionType}
                    jobTypes={jobTypes}
                    assessmentTypes={assessmentTypes}
                    domains={domains}
                    primarySkills={primarySkills}
                    experiences={experiences}
                    cities={cities}
                    designations={designations}
                    salaries={salaries}
                    onCloseJobPosting={onCloseJobPostingHandler}
                    onJobPostingSave={onJobPostingSaveHandler}
                />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseJobPostingHandler = () => {
        modalUtils.closeModal();
    };

    const onJobPostingSaveHandler = (objJobPosting: JobPostingRequestModel) => {
        console.log(companyConfiguration);
        setRefreshJobPosting(2);
        objJobPosting.companyId = companyConfiguration.companyId;
        objJobPosting.recruiterId = props.profileId;
        objJobPosting.userId = props.userId;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        jobPostingService.saveJobPosting(objJobPosting).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getJobPostings();
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onJobPostingActionHandler = (obj: JobPostingRequestModel, actionType: string) => {
        if (actionType === PageModeEnum.Edit) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            jobPostingService.getJobPostingById(obj.requirementId).then((res) => {
                modalUtils.closeModal();
                openJobPostingSaveModel(res.data, actionType);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            openJobPostingSaveModel(obj, actionType);
        }
    };

    const onVendorSaveHandler = (objVendor: JobPostingVendorRequestModel) => {
        modalUtils.closeModal();
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        jobPostingService.saveJobPostingVendor(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                onJobPostingVendorActionHandler(objVendor.requirementId);
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseVendorHandler = () => {
        modalUtils.closeModal();
    };

    const onJobPostingVendorActionHandler = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getRequirementVendors(requirementId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add JobPosting",
                content: (
                    <JobPostingVendorPopUp
                        requirementVendors={res.data.requirementVendors}
                        vendorForRequiremrntList={res.data.vendorsForRequirement}
                        requirementId={requirementId}
                        onVendorSave={onVendorSaveHandler}
                        onCloseVendor={onCloseVendorHandler}
                    />
                ),
                size: ModalSize.XL,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshJobPosting > 2 &&
                    <JobPostingGridComponent
                        key={refreshJobPosting}
                        jobPostingList={jobPostingList}
                        onJobPostingAction={onJobPostingActionHandler}
                        onJobPostingVendorAction={onJobPostingVendorActionHandler} />
                }
            </div>
        </div>
    );
};
export default JobPostingComponent;