import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import alertUtils from "../../../../utils/toaster-utils";
import ReleaseCandidatesModel from "../../../../models/admin/company/response/ReleaseCandidatesModel";
import ReleaseCandidatesRequestModel from "../../../../models/admin/company/request/ReleaseCandidatesRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";

interface ReleaseCandidateGridProps {
    candidateList: ReleaseCandidatesModel[];
    onReleaseCandidateAction: (objReleaseCandidate: ReleaseCandidatesRequestModel) => void;
}

const ReleaseCandidateGridComponent = (props: ReleaseCandidateGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: ReleaseCandidatesModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { releaseCandidate(row); }}>Release</a>
            </div>
        );
    };

    const columnsCandidates = [
        { dataField: "uniqueId", text: "UniqueId" },
        { dataField: "candidateName", text: "Candidate Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile Number" },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "jobCode", text: "JobCode" },
        { dataField: "interviewDate", text: "Interview Dt" },
        { dataField: "totalRating", text: "Rating" },
        { dataField: "isReleased", text: "", formatter: ActionFormatter }
    ];

    const releaseCandidate = (row: ReleaseCandidatesModel) => {
        let objReleaseCandidate: ReleaseCandidatesRequestModel = {
            candidateId: row.candidateId,
            profileId: row.profileId,
            userId: GuidEnum.Empty
        };
        props.onReleaseCandidateAction(objReleaseCandidate);
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="profileId"
                    data={props.candidateList}
                    columns={columnsCandidates}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default ReleaseCandidateGridComponent;