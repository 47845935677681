import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import CandidateAdminModel from "../../models/admin/candidate/response/CandidateAdminModel";
import CandidatePasswordRequestModel from "../../models/admin/candidate/request/CandidatePasswordRequestModel";
import RegisteredCandidatesSearchInputModel from "../../models/admin/candidate/request/RegisteredCandidatesSearchInputModel";
import RegisteredCandidatesModel from "../../models/admin/candidate/response/RegisteredCandidatesModel";
import PagedListModel from "../../models/common/PagedListModel";

class AdminCandidateService {
    async getCandidateByUniqueId(uniqueId: string) {
        let res: BaseResponseList<CandidateAdminModel[]> = await httpClient.get<BaseResponseList<CandidateAdminModel[]>>(AppConfig.admin.candidate.getCandidateByUniqueId + "/" + uniqueId);
        return res;
    }

    async getCandidatePassword(input: CandidatePasswordRequestModel) {
        let res: BaseResponseList<CandidateAdminModel[]> = await httpClient.post<BaseResponseList<CandidateAdminModel[]>>(AppConfig.admin.candidate.getCandidatePassword, input);
        return res;
    }

    async byPassPaymentGateway(candidateId: string) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.candidate.byPassPaymentGateway + "/" + candidateId);
        return res;
    }

    async getRegisteredCandidates(inputParms: RegisteredCandidatesSearchInputModel) {
        let res: PagedListModel<RegisteredCandidatesModel> = await httpClient.post<PagedListModel<RegisteredCandidatesModel>>(AppConfig.admin.candidate.getRegisteredCandidates, inputParms);
        return res;
    }

    async verifyCandidate(candidateId: string) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.candidate.verifyCandidate + "/" + candidateId);
        return res;
    }
}
const adminCandidateService = new AdminCandidateService();
export default adminCandidateService;