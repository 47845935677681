import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminUserModel from "../../../models/admin/dashboard/response/AdminUserModel";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import AdminUserPopUp from "./AdminUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import AdminUserGridComponent from "./AdminUserGridComponent";
import AdminUserSearchInputModel from "../../../models/admin/dashboard/request/AdminUserSearchInputModel";
import adminDashboardService from "../../../services/admin/admin-dashboard-service";
import AdminUserRequestModel from "../../../models/admin/dashboard/request/AdminUserRequestModel";
import loaderUtils from "../../../utils/loader-utils";
import AdminCompanyMappingPopUp from "./AdminCompanyMappingComponent";
import AdminUserCompanyMappingRequestModel from "../../../models/admin/dashboard/request/AdminUserCompanyMappingRequestModel";
import IdNameModel from "../../../models/common/IdNameModel";

interface AdminUserProps {
    companiesList: IdNameModel[];
};

const AdminUserComponent = (props: AdminUserProps) => {
    const [adminUserPagedList, setAdminUserPagedList] = useState<PagedListModel<AdminUserModel>>();
    const [adminUserList, setAdminUserList] = useState<AdminUserModel[]>([]);
    const [refreshAdminUser, setRefreshAdminUser] = useState<number>(2);

    useEffect(() => {
        getAdminUsers();
    }, []);

    const getAdminUsers = () => {
        setRefreshAdminUser(2);
        var inputData: AdminUserSearchInputModel = {
            name: "",
            page: 1,
            pageSize: 100,
            sort: "name",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminDashboardService.getAdminUsers(inputData).then((res) => {
            modalUtils.closeModal();
            setAdminUserList([]);
            setAdminUserPagedList(res);
            setAdminUserList(res.items);
            setRefreshAdminUser(refreshAdminUser * 5);
        }).catch((error) => {
            setRefreshAdminUser(refreshAdminUser * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onAdminUserActionHandler = (obj: AdminUserRequestModel, actionType: string) => {
        openAdminUserSaveModel(obj, actionType);
    };

    const openAdminUserSaveModel = (adminUserRequestModel: AdminUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add User",
            content: (
                <AdminUserPopUp
                    objUser={adminUserRequestModel}
                    actionType={actionType}
                    onCloseAdminUser={onCloseAdminUserHandler}
                    onAdminUserSave={onAdminUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseAdminUserHandler = () => {
        modalUtils.closeModal();
    };

    const onAdminUserSaveHandler = (objAdminUser: AdminUserRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.saveAdminUser(objAdminUser).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getAdminUsers();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onAdminUserDeleteActionHandler = (objAdminUser: AdminUserRequestModel) => {
        const objUser = objAdminUser;
        modalUtils.showConfirmation(
            {
                title: "Confirm Admin User Delete",
                message: "Are you sure you want to delete the User.",
                buttons: ["Yes", "No"],
            },
            deleteUser.bind(this, objUser)
        );
    };

    const deleteUser = (objAdminUser: AdminUserRequestModel, action: string) => {
        if (!objAdminUser) {
            return;
        }
        if (action === "Yes") {
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            adminDashboardService.deleteAdminUser(objAdminUser).then((res) => {
                modalUtils.closeModal();
                alertUtils.showSuccess(res.informationMessages.toString());
                getAdminUsers();
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onMapCompanyActionHandler = (adminId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.getAdminCompanyMapping(adminId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add User",
                content: (
                    <AdminCompanyMappingPopUp
                        mappingList={res.items}
                        adminId={adminId}
                        companyList={props.companiesList}
                        onCloseMapping={onCloseAdminUserHandler}
                        onMappingSave={onMappingSaveHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onMappingSaveHandler = (objMapping: AdminUserCompanyMappingRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.updateAdminCompanyMapping(objMapping).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            onMapCompanyActionHandler(objMapping.adminId);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {adminUserList.length > 0 && refreshAdminUser > 2 &&
                    <AdminUserGridComponent
                        key={refreshAdminUser}
                        adminUsersList={adminUserList}
                        onAdminUserAction={onAdminUserActionHandler}
                        onAdminUserDeleteAction={onAdminUserDeleteActionHandler}
                        onMapCompanyAction={onMapCompanyActionHandler} />
                }
            </div>
        </div>
    );
};
export default AdminUserComponent;