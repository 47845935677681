import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import alertUtils from "../../../../utils/toaster-utils";
import RegisteredCompaniesModel from "../../../../models/admin/company/response/RegisteredCompaniesModel";

interface CompanyConfigurationGridProps {
    companyList: RegisteredCompaniesModel[];
    onEditConfigurationAction: (companyId: string) => void;
}

const RegisteredCandidatesGridComponent = (props: CompanyConfigurationGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: RegisteredCompaniesModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editConfiguration(row.companyId); }}>Edit</a>                
            </div>
        );
    };

    const editConfiguration = (companyId: string) => {
        props.onEditConfigurationAction(companyId);
    };


    const columnsCompany = [
        { dataField: "companyName", text: "Company Name" },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "noOfInterviewsAllowed", text: "Interviews Allowed" },
        { dataField: "startDate", text: "Start Dt" },
        { dataField: "endDate", text: "End Dt" },
        { dataField: "isAuthorize", text: "Authorized" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="companyId"
                    data={props.companyList}
                    columns={columnsCompany}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default RegisteredCandidatesGridComponent;