import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminInterviewsModel from "../../../../models/admin/interviews/response/AdminInterviewsModel";
import UpdateInterviewRequestModel from "../../../../models/admin/interviews/request/UpdateInterviewRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";
import DataGrid from "../../../common/table/DataGrid";
import copyClickboardUtils from "../../../../utils/copy-clipboard";

interface InterviewsGridProps {
    candidateList: AdminInterviewsModel[];
    onUpdateInterviewAction: (objInterview: UpdateInterviewRequestModel, primarySkillId: number) => void;
    onViewRatingAction: (interviewId: string) => void;
}

const InterviewsGridComponent = (props: InterviewsGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: AdminInterviewsModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                {!row.isRated &&
                    <a onClick={() => { reScheduleInterview(row); }}>Re Schedule</a>
                }

                {row.isRated &&
                    <a onClick={() => { viewRating(row); }}>Rating</a>
                }
                <a onClick={() => { updateComments(row); }}>Update</a>
            </div>
        );
    };

    const CandidateNameFormatter = (
        cell: any,
        row: AdminInterviewsModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                {row.isRegisteredCandidate &&
                    <span style={{ color: "green" }}>{row.candidateName}</span>
                }
                {!row.isRegisteredCandidate &&
                    <span>{row.candidateName}</span>
                }
            </div>
        );
    };

    const reScheduleInterview = (row: AdminInterviewsModel) => {
        var objCandidate: UpdateInterviewRequestModel = {
            interviewId: row.interviewId,
            interviewDate: new Date(),
            timeSlotId: 0,
            interviewerId: "",
            userId: GuidEnum.Empty
        };
        props.onUpdateInterviewAction(objCandidate, row.primarySkillId);
    };

    const viewRating = (row: AdminInterviewsModel) => {
        props.onViewRatingAction(row.interviewId);
    };

    const updateComments = (row: AdminInterviewsModel) => {
    };

    const columnsCandidates = [
        { dataField: "interviewUniqueId", text: "Interview Id" },
        { dataField: "interviewDate", text: "Interview Dt" },
        { dataField: "candidateName", text: "Name", formatter: CandidateNameFormatter },
        { dataField: "emailId", text: "Email Id" },
        { dataField: "mobileNumber", text: "Mobile No" },
        { dataField: "interviewerName", text: "Interviewer" },
        { dataField: "primarySkillName", text: "Primary Skill" },
        { dataField: "totalRating", text: "Rating" },
        { dataField: "companyName", text: "Company" },
        { dataField: "recruiterName", text: "Recruiter" },
        { dataField: "designationName", text: "Designation" },
        { dataField: "status", text: "Status" },
        { dataField: "isRescheduled", text: "Is Rescheduled" },
        { dataField: "iAIAdminName", text: "Admin Name" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="interviewId"
                    data={props.candidateList}
                    columns={columnsCandidates}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default InterviewsGridComponent;