import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import subUserService from "../../../services/company/sub-user-service";
import SubUserSearchInputModel from "../../../models/company/subUser/request/SubUserSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import SubUserModel from "../../../models/company/subUser/response/SubUserModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import SubUserPopUp from "./SubUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import SubUserRequestModel from "../../../models/company/subUser/request/SubUserRequestModel";
import SubUserGridComponent from "./SubUserGridComponent";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import departmentService from "../../../services/company/department-service";
import loaderUtils from "../../../utils/loader-utils";

const SubUserComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [subUserPagedList, setSubUserPagedList] = useState<PagedListModel<SubUserModel>>();
    const [subUserList, setSubUserList] = useState<SubUserModel[]>([]);
    const [refreshSubUser, setRefreshSubUser] = useState<number>(2);
    const [roles, setRoles] = useState<IdNameModel[]>([]);
    const [city, setCity] = useState<IdNameModel[]>([]);
    const [departments, setDepartments] = useState<IdNameModel[]>([]);

    useEffect(() => {
        getSubUsers();
        fillUserTypes();
        fillDepartments();
        fillCities(1);
    }, []);

    const getSubUsers = () => {
        if (loggedInUser?.profileId) {
            var inputData: SubUserSearchInputModel = {
                companyId: loggedInUser?.profileId,
                subUserName: "",
                page: 1,
                pageSize: 100,
                sort: "Name",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            subUserService.getSubUsers(inputData).then((res) => {
                modalUtils.closeModal();
                setSubUserList([]);
                setSubUserPagedList(res);
                setSubUserList(res.items);
                setRefreshSubUser(refreshSubUser * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    const fillUserTypes = () => {
        let roles: IdNameModel[] = [];
        let roleRecruiter: IdNameModel = {
            id: RoleEnum.ClientRecruiter,
            name: "Client Recruiter",
            guId: GuidEnum.Empty
        }
        roles.push(roleRecruiter);
        let rolePanel: IdNameModel = {
            id: RoleEnum.PanelManagement,
            name: "Panel Management",
            guId: GuidEnum.Empty
        }
        roles.push(rolePanel);
        setRoles(roles);
    };

    const fillDepartments = () => {
        if (loggedInUser) {
            departmentService.fillDepartments(loggedInUser.profileId).then((res) => {
                setDepartments(res.items);
            }).catch((error) => {
                alertUtils.showError(error);
            });
        }
    };

    const fillCities = (countryId: number) => {
        masterDataService.loadCities(countryId).then((res) => {
            setCity(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const openSubUserSaveModel = (subUserRequestModel: SubUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add SubUser",
            content: (
                <SubUserPopUp
                    objSubUser={subUserRequestModel}
                    actionType={actionType}
                    roles={roles}
                    departments={departments}
                    cities={city}
                    onCloseSubUser={onCloseSubUserHandler}
                    onSubUserSave={onSubUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseSubUserHandler = () => {
        modalUtils.closeModal();
    };

    const onSubUserSaveHandler = (objSubUser: SubUserRequestModel) => {
        setRefreshSubUser(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        subUserService.saveSubUser(objSubUser).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());                
                getSubUsers();
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onSubUserActionHandler = (obj: SubUserRequestModel, actionType: string) => {
        if (actionType === PageModeEnum.Edit) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            subUserService.getSubUserById(obj.subUserId).then((res) => {
                modalUtils.closeModal();
                openSubUserSaveModel(res.data, actionType);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        } else {
            openSubUserSaveModel(obj, actionType);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshSubUser > 2 &&
                    <SubUserGridComponent
                        key={refreshSubUser}
                        subUserList={subUserList}
                        onSubUserAction={onSubUserActionHandler} />
                }
            </div>
        </div>
    );
};
export default SubUserComponent;