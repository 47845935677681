import React from "react";
import DataGrid from "../../common/table/DataGrid";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";

interface JobPostingVendorsGridProps {
    vendorList: VendorSubUserRequestModel[];
}

const JobPostingVendorsGridComponent = (props: JobPostingVendorsGridProps) => {
    const columnsVendors = [
        { dataField: "vendorName", text: "Vendor Name" },
        { dataField: "vendorEmailId", text: "Email Id" },
        { dataField: "vendorPhoneNumber", text: "Phone Number" }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="vendorSubUserId"
                    data={props.vendorList}
                    columns={columnsVendors}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default JobPostingVendorsGridComponent;