import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import AppliedProfilesGridComponent from "./AppliedProfilesGridComponent";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import AppliedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/AppliedProfilesGridModel";
import AppliedProfilesSearchInputModel from "../../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesSearchInputModel";
import AppliedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesRequestModel";
import modalUtils from "../../../utils/modal-utils";
import addProfilesService from "../../../services/company/add-profiles-service";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../utils/loader-utils";

interface AppliedProfilesProps {
    ratingList: IdNameModel[];
    requirementId: string;
};

const AppliedProfilesComponent = (props: AppliedProfilesProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [appliedProfilesPagedList, setAppliedProfilesPagedList] = useState<PagedListModel<AppliedProfilesGridModel>>();
    const [appliedProfilesList, setAppliedProfilesList] = useState<AppliedProfilesGridModel[]>([]);
    const [refreshAppliedProfiles, setRefreshAppliedProfiles] = useState<number>(2);

    useEffect(() => {
        getAppliedProfiles();
    }, []);

    const getAppliedProfiles = () => {
        setRefreshAppliedProfiles(2)
        if (loggedInUser?.profileId) {
            var inputData: AppliedProfilesSearchInputModel = {
                requirementId: props.requirementId,
                totalRating: null,
                page: 1,
                pageSize: 100,
                sort: "CandidateName",
                sortDir: SortDirection.Asc
            };
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            relatedShortlistedProfilesService.getAppliedProfilesGrid(inputData).then((res) => {
                modalUtils.closeModal();
                setAppliedProfilesList([]);
                setAppliedProfilesPagedList(res);
                setAppliedProfilesList(res.items);
                setRefreshAppliedProfiles(refreshAppliedProfiles * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    const onShortlistProfileHandler = (objShortlistProfile: AppliedProfilesRequestModel) => {
        setRefreshAppliedProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        relatedShortlistedProfilesService.saveAppliedProfile(objShortlistProfile).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getAppliedProfiles();
            modalUtils.closeModal();
        }).catch((error) => {
            setRefreshAppliedProfiles(refreshAppliedProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onRatingDetailsHandler = (objShortlistProfile: AppliedProfilesRequestModel, hasAccess: boolean) => {
        if (hasAccess) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            addProfilesService.interviewRatingDetails(objShortlistProfile.interviewId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    const config: GeneralPoupConfig = {
                        title: "Rating Details",
                        content: (
                            <RatingDetailsPopUp
                                objRatingDetails={res.data}
                            />
                        ),
                        size: ModalSize.XL,
                        className: "model-rating-details-badge"
                    };
                    modalUtils.showPopup(config);
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");                
            });
        } else {
            setRefreshAppliedProfiles(2);
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            relatedShortlistedProfilesService.saveCandidateAccessToViewProfile(objShortlistProfile).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    alertUtils.showSuccess(res.informationMessages.toString());
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
                getAppliedProfiles();
                modalUtils.closeModal();
            }).catch((error) => {
                setRefreshAppliedProfiles(refreshAppliedProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshAppliedProfiles > 2 &&
                    <AppliedProfilesGridComponent
                        key={refreshAppliedProfiles}
                        appliedProfilesList={appliedProfilesList}
                        requirementId={props.requirementId}
                        onShortlistProfile={onShortlistProfileHandler}
                        onRatingDetails={onRatingDetailsHandler} />
                }
            </div>
        </div>
    );
};
export default AppliedProfilesComponent;