import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";
import EncryptUtil from '../../../../utils/encode-utils';

interface ByPassPaymentGatewayGridProps {
    candidateList: CandidateAdminModel[];
}
const CandidatePasswordGridComponent = (props: ByPassPaymentGatewayGridProps) => {
    const PasswordFormatter = (
        cell: any,
        row: CandidateAdminModel,
        rowIndex: number
    ) => {
        return (
            EncryptUtil.getDecoded(row.candidatePassword)
        );
    };

    const columnsCandidates = [
        { dataField: "uniqueId", text: "UniqueId" },
        { dataField: "candidateName", text: "Name" },
        { dataField: "emailId", text: "Email Id" },        
        { dataField: "candidatePassword", text: "Password", formatter: PasswordFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="candidateId"
                    data={props.candidateList}
                    columns={columnsCandidates}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default CandidatePasswordGridComponent;