import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import AdminInterviewsModel from "../../../../models/admin/interviews/response/AdminInterviewsModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import AdminInterviewsSearchInputModel from "../../../../models/admin/interviews/request/AdminInterviewsSearchInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import adminInterviewsService from "../../../../services/admin/admin-interviews-service";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import InterviewsGridComponent from "./InterviewsGridComponent";
import UpdateInterviewRequestModel from "../../../../models/admin/interviews/request/UpdateInterviewRequestModel";
import UpdateInterviewPopUp from "./UpdateInterviewComponent";
import adminDashboardService from "../../../../services/admin/admin-dashboard-service";
import RatingDetailsPopUp from "../../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../../utils/loader-utils";

interface InterviewsProps {
    primarySkillsList: IdNameModel[];
    timeSlotsList: IdNameModel[];
    interviewerList: IdNameModel[];
    cityList: IdNameModel[];
};

const InterviewsComponent = (props: InterviewsProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<AdminInterviewsModel>>();
    const [candidateList, setCandidateList] = useState<AdminInterviewsModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -7) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [interviewerSearch, setInterviewerSearch] = useState<string>("");
    const [citySearch, setCitySearch] = useState<number>(0);
    const [registeredCandidatesSearch, setRegisteredCandidatesSearch] = useState<boolean>(false);
    const [ratedCandidatesSearch, setRatedCandidatesSearch] = useState<boolean>(false);

    useEffect(() => {
        clearSearch();
    }, []);

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getCandidates = () => {
        setRefreshCandidate(2);
        var inputData: AdminInterviewsSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            primarySkillId: primarySKillSearch,
            interviewerId: interviewerSearch === "" ? null : interviewerSearch,
            cityId: citySearch,
            registeredCandidates: registeredCandidatesSearch,
            ratedCandidates: ratedCandidatesSearch,
            name: "",
            totalRating: null,
            companyName: null,
            page: 1,
            pageSize: 100,
            sort: "CandidateName",
            sortDir: SortDirection.Asc
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviews(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);            
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setStartDateSearch("");
        setEndDateSearch("");
        setPrimarySkillSearch(0);
        setInterviewerSearch("");
        setCitySearch(0);
        setRefreshCandidate(2);
    };

    const onUpdateInterviewActionHandler = (objInterview: UpdateInterviewRequestModel, primarySkillId: number) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewersByPrimarySkill(primarySkillId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add User",
                content: (
                    <UpdateInterviewPopUp
                        objInterview={objInterview}
                        interviewerList={res.items.length > 0 ? res.items : props.interviewerList}
                        timeSlotList={props.timeSlotsList}
                        onRescheduleInterviewAction={onRescheduleInterviewActionHandler}
                        onCloseUpdateInterview={onCloseUpdateInterviewActionHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onCloseUpdateInterviewActionHandler = () => {
        modalUtils.closeModal();
    };

    const onRescheduleInterviewActionHandler = (objInterview: UpdateInterviewRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminInterviewsService.updateInterview(objInterview).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }            
            getCandidates();
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onViewRatingActionHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminDashboardService.interviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Rating Details",
                    content: (
                        <RatingDetailsPopUp
                            objRatingDetails={res.data}
                        />
                    ),
                    size: ModalSize.XL,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Eror fetching Schedules");            
        });
    };

    const handleRegisteredCandidatesSearch = (): void => {
        setRegisteredCandidatesSearch(!registeredCandidatesSearch);
    };

    const handleRatedCandidatesSearch = (): void => {
        setRatedCandidatesSearch(!ratedCandidatesSearch);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-2">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Interviewer</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setInterviewerSearch(e.target.value)}>
                            <option value="">All</option>
                            {props.interviewerList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Location</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setCitySearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.cityList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates()}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="quform-element form-group">
                            <div className="quform-input">
                                <input type="checkbox" checked={registeredCandidatesSearch} onChange={handleRegisteredCandidatesSearch} />&nbsp;&nbsp;
                                <label> Registered Candidates </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="quform-element form-group">
                            <div className="quform-input">
                                <input type="checkbox" checked={ratedCandidatesSearch} onChange={handleRatedCandidatesSearch} />&nbsp;&nbsp;
                                <label> Rated Candidates </label>
                            </div>
                        </div>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <InterviewsGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        onUpdateInterviewAction={onUpdateInterviewActionHandler}
                        onViewRatingAction={onViewRatingActionHandler} />
                }
            </div>
        </div>
    );
};
export default InterviewsComponent;