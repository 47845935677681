import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import DataGrid from "../../../common/table/DataGrid";
import CompanyProfilesDashboardModel from "../../../../models/admin/company/response/CompanyProfilesDashboardModel";

interface CompanyProfilesDashboardGridProps {
    companyList: CompanyProfilesDashboardModel[];
};

const CompanyProfilesDashboardGridComponent = (props: CompanyProfilesDashboardGridProps) => {

    const columnsCompany = [
        { dataField: "companyName", text: "Company Name" },
        { dataField: "noOfProfilesReceived", text: "No Of Profiles Received" },
        { dataField: "noOfProfilesScheduled", text: "No of Profiles Scheduled" },
        { dataField: "noOfProfilesRated", text: "No of Profiles Rated" }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="companyId"
                    data={props.companyList}
                    columns={columnsCompany}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default CompanyProfilesDashboardGridComponent;