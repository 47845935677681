import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import JobPostingSearchInputModel from "../../models/company/jobPosting/request/JobPostingSearchInputModel";
import CompanyDashboardModel from "../../models/company/dashboard/response/CompanyDashboardModel";
import PagedListModel from "../../models/common/PagedListModel";

class CompanyDashboardService {
    async getJobPostingsDashboard(inputParms: JobPostingSearchInputModel) {
        let res: PagedListModel<CompanyDashboardModel> = await httpClient.post<PagedListModel<CompanyDashboardModel>>(AppConfig.company.dashboard.getJobPostingDashboard, inputParms);
        return res;
    }
}
const companyDashboardService = new CompanyDashboardService();
export default companyDashboardService;